import $ from 'jquery'
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import 'mapbox-gl/dist/mapbox-gl.css'
import * as turf from '@turf/turf'
import { UOMConv } from './utils'
import { MESSAGES } from './messages'
import { pApiClient } from './api'
import { useMessenger } from '@/composables/messenger'
import { LocationClass } from './models/Location'

const { addMessage } = useMessenger()

const theme = window.localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'

class ToggleBuildingsControl {
  onAdd(map) {
    this._map = map
    this.layer3dbuildings = map.layer3dbuildings
    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group bg-surface'

    this.toggleButton = document.createElement('button')
    this.toggleButton.className = 'mapboxgl-ctrl-icon material-symbols-outlined m-1'
    this.toggleButton.innerHTML = 'home_work'
    this.toggleButton.title = 'Toggle 3D Buildings'

    this.toggleButton.addEventListener('click', () => {
      map.getStyle().layers.forEach((layer) => {
        if (layer.id == 'add-3d-buildings') {
          const visible = layer.layout?.visibility == 'none' ? 'visible' : 'none'
          map.setLayoutProperty(layer.id, 'visibility', visible)
        }
      })
    })

    this._container.appendChild(this.toggleButton)
    return this._container
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}

class SearchBoxControl {
  onAdd(map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl'

    this._container.innerHTML =
      '<div class="input-group mb-3 imput-group-sm" id="map-searchbox-container">' +
      '<input type="text" style="background-color: #f8f9fa; padding:11px; font-size: 1rem; border-radius: 4px 0 0 4px; border: 1px solid #aaa"  placeholder="Eynham rd, London" aria-label="Eynham rd, London" aria-describedby="button-search-loc">' +
      '<button style="background-color: #888; padding:11px; font-size: 1rem; border-radius: 0 4px 4px 0; border: 1px solid #888; color: white" type="button" id="button-search-loc">Fly!</button></div>'
    return this._container
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }

  static setEvents(mapInstance) {
    $('#map-searchbox-container>input').keypress((evt) => {
      if (evt.which == 13) {
        geoSearch(evt.target.value, function (locObj) {
          mapInstance.setGeoLocation(locObj)
        })
      }
    })

    $('#map-searchbox-container>button').click(() => {
      geoSearch($('#map-searchbox-container>input').val(), function (locObj) {
        mapInstance.setGeoLocation(locObj)
      })
    })
  }
}

class repositionControls {
  constructor(mapdraw) {
    this._mapDraw = mapdraw
  }
  onAdd(map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-reposition'

    this.rotateLabel = document.createElement('label')
    this.rotateLabel.htmlFor = 'map-rotate-slider'
    this.rotateLabel.innerHTML = 'Rotate design'
    this.rotateLabel.className = 'form-label fw-bold text-center'

    this.rotateSlider = document.createElement('input')
    this.rotateSlider.type = 'range'
    this.rotateSlider.id = 'map-rotate-slider'
    this.rotateSlider.className = 'form-range'
    this.rotateSlider.min = '0'
    this.rotateSlider.max = '360'
    this.rotateSlider.step = '1'
    this.rotateSlider.value = '0'

    this._container.appendChild(this.rotateLabel)
    this._container.appendChild(this.rotateSlider)

    this.prevRotateVal = 0
    this.currentRotateVal = 0

    this.rotateSlider.addEventListener('input', ({ target }) => {
      this.prevRotateVal = this.currentRotateVal
      this.currentRotateVal = target.value
      this.rotateGeometry(this.currentRotateVal - this.prevRotateVal)
    })

    return this._container
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }

  rotateGeometry(value) {
    const coordinates = this._mapDraw.getAll().features[0].geometry.coordinates[0]
    const poly = turf.polygon([coordinates])
    const rotatedPoly = turf.transformRotate(poly, Number(value))
    this._mapDraw.deleteAll()
    this._mapDraw.add(rotatedPoly)

    this._mapDraw.newCoords = rotatedPoly.geometry.coordinates[0]
    this._mapDraw.rotateAngle = this.currentRotateVal
  }
}

class SelectedAreaControl {
  onAdd(map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl text-surface-dark'

    this._container.innerHTML =
      '<div id="map-area-box">' +
      'Selected area: <span id="map-area-box-value"></span><span id="map-area-box-uom"></span>' +
      '</div>'
    return this._container
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }

  static refreshArea(mapInstance) {
    let area = 0
    let poly

    if (mapInstance._mapDraw != null) {
      poly = mapInstance.getUserPolygon(true)
    } else {
      poly = mapInstance._sitePolyGeoJSON
    }

    if (poly != null) {
      mapInstance.setStartCoords()
      area = Math.floor(turf.area(poly))
    }

    if (mapInstance.som == UOMConv.MEASSYS.METRIC) {
      $('#map-area-box-uom')[0].innerHTML = 'm<sup>2</sup>'
    } else {
      area = UOMConv.conv(area, UOMConv.UMs.m2.code, UOMConv.MEASSYS.IMPERIAL, true).value
      $('#map-area-box-uom')[0].innerHTML = 'ft<sup>2</sup>'
    }

    $('#map-area-box-value')[0].innerHTML = area
  }

  static setEvents(mapInstance) {
    $(mapInstance).on('map.selectedAreaChanged', function () {
      SelectedAreaControl.refreshArea(mapInstance)
    })
  }
}

class mapLySrcRef {
  sourceID
  layerID

  constructor(l, s) {
    this.layerID = l
    this.sourceID = s
  }
}

class pMapClass {
  layer3dbuildings = null

  my3DLayer = {
    id: '3d-buildings',
    source: 'openmaptiles',
    'source-layer': 'building',
    filter: ['==', 'extrude', 'true'],
    type: 'fill-extrusion',
    minzoom: 15,
    paint: {
      'fill-extrusion-color': '#aaa',

      // use an 'interpolate' expression to add a smooth transition effect to the
      // buildings as the user zooms in
      'fill-extrusion-height': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15,
        0,
        15.05,
        ['get', 'height']
      ],
      'fill-extrusion-base': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15,
        0,
        15.05,
        ['get', 'min_height']
      ],
      'fill-extrusion-opacity': 0.6
    }
  }

  som = UOMConv.BASE_SOM
  _instance = null

  _map = null

  _buildingShown = false

  _startCoords = null
  _defaultZoom = null
  _defaultPitch = null

  _drawingCfg = null

  _geoLocation = null

  _polygonVisible = true

  _sitePolyRefs = null
  _sitePolyGeoJSON = null

  hasSearchBox = false

  _areaCtrl = null

  _token = ''

  constructor(token) {
    if (pMapClass._instance != null) return pMapClass._instance
    pMapClass._instance = this
    this._token = token
    let self = this
    $.getJSON('/map/3dlayer.json').done((d) => {
      self.layer3dbuildings = d
    })
  }

  /**
   *
   * @returns {pMapClass}
   */
  static getInstance() {
    if (pMapClass._instance == null) pMapClass._instance = new pMapClass()

    return pMapClass._instance
  }

  drawSelectedPoly(vertexes) {
    let poly2Show = turf.featureCollection([
      turf.polygon(
        [vertexes],
        {
          color: 'blue'
        },
        {
          id: 'selectedPoly'
        }
      )
    ])

    this._map.addSource('selected-poly', {
      type: 'geojson',
      data: poly2Show
    })

    this._map.addLayer({
      id: 'selected-poly-fill',
      type: 'fill',
      source: 'selected-poly',
      paint: {
        'fill-color': ['get', 'color'],

        'fill-opacity': 0.3
      }
    })

    this.goBackToPolygon(poly2Show)
  }

  static getAreaFromCoords(arrayCoords) {
    let poly = turf.polygon([arrayCoords])
    return turf.area(poly)
  }

  removeLayerSource(srcID, layerID = null) {
    if (layerID == null) {
      layerID = srcID.layerID
      srcID = srcID.sourceID
    }
    let src = this._map.getSource(srcID)
    let layer = this._map.getLayer(layerID)

    if (layer != null) {
      this._map.removeLayer(layerID)
    }

    if (src != null) {
      this._map.removeSource(srcID)
    }
  }

  getCanvasImg() {
    return this._map.getCanvas().toDataURL()
  }

  refreshSourceFeatures(sourceID, features) {
    this._map.getSource(sourceID).setData({
      type: 'FeatureCollection',
      features: features
    })
  }

  endDrawingMode() {
    this._mapDraw.changeMode(this._mapDraw.modes.SIMPLE_SELECT)
  }

  drawSitePolygonFromCoords(coordsArray) {
    this._sitePolyGeoJSON = turf.polygon([coordsArray])
    this._sitePolyRefs = this.drawPolygon(this._sitePolyGeoJSON, 'prjsite')

    SelectedAreaControl.refreshArea(this)
  }

  removeSitePolygon() {
    if (this._sitePolyRefs == null) return
    this.removeLayerSource(this._sitePolyRefs)
    this._sitePoly = null
    this._sitePolyGeoJSON = null

    return this
  }

  drawPolygon(poly, id) {
    id = id == null ? poly.id : id
    let res = this._genPolyIDs(id)

    this.removeLayerSource(res)

    this._map.addSource(res.sourceID, {
      type: 'geojson',
      data: poly
    })

    this._map.addLayer({
      id: res.layerID,
      type: 'fill',
      source: res.sourceID,
      paint: {
        'fill-color': 'blue',
        'fill-opacity': 0.3
      }
    })

    return res
  }

  /**
   *
   * @param {*} id
   * @returns {mapLySrcRef}
   */
  _genExtrusionIDs(id) {
    return new mapLySrcRef(`block-${id}-extrusion`, `block-${id}-src`)
  }

  _genPolyIDs(id) {
    return new mapLySrcRef(`poly-${id}-layer`, `poly-${id}-src`)
  }

  drawExtrudedBlock(polygon, id) {
    let drawingLayer = 'gl-draw-polygon-fill-inactive.cold'
    let r = this._genExtrusionIDs(id)

    let src = this._map.getSource(r.sourceID)
    let layer = this._map.getLayer(r.layerID)

    if (layer != null) {
      this._map.removeLayer(r.layerID)
    }

    if (src != null) {
      this._map.removeSource(r.sourceID)
    }

    this._map.addSource(r.sourceID, {
      type: 'geojson',
      data: polygon
    })

    let newLayer = {
      id: r.layerID,
      type: 'fill-extrusion',
      source: r.sourceID,
      paint: {
        'fill-extrusion-color': ['get', 'color'],
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-base': ['get', 'base_height'],
        'fill-extrusion-opacity': 1
      }
    }

    if (this._map.getLayer(drawingLayer) != null) {
      this._map.addLayer(newLayer, drawingLayer)
    } else {
      this._map.addLayer(newLayer)
    }

    return r
  }

  drawBuilding(bldgBase, bldgWin) {
    this.drawExtrudedBlock(bldgBase, 'bldg')
    if (bldgWin != null) this.drawExtrudedBlock(bldgWin, 'win')

    this._buildingShown = true
    //this.goBackToPolygon(solution2Show);
  }

  changeBlockColor(layerID, color) {
    this._map.setPaintProperty(layerID, 'fill-extrusion-color', color)
  }

  changeBlockHeight(layerID, height, base_height) {
    this._map.setPaintProperty(layerID, 'fill-extrusion-height', height)
    if (base_height != null) {
      this._map.setPaintProperty(layerID, 'fill-extrusion-base', base_height)
    }
  }

  removeBuilding() {
    if (this._buildingShown) {
      this.removeLayerSource(this._genExtrusionIDs('bldg'))
      this.removeLayerSource(this._genExtrusionIDs('win'))
      this._buildingShown = false
    }
  }

  forceOnePolygon(e) {
    if (e.type == 'draw.modechange' && e.mode == this._mapDraw.modes.DRAW_POLYGON) {
      if (this.getUserPolygon() != null) {
        this._mapDraw.changeMode(this._mapDraw.modes.SIMPLE_SELECT)
        addMessage({ text: MESSAGES.MAP_DRAW_ONLY_ONE_POLYGON_ALLOWED, color: 'warning' })
      }
    }
  }

  getUserPolygon(full) {
    let features = this._mapDraw.getAll().features
    if (features.length > 0 && features[0].geometry.coordinates[0].length > 3)
      if (full === true) {
        return features[0]
      } else {
        return features[0].geometry.coordinates[0]
      }

    return null
  }

  convertPolygonCoords(polygon) {
    let res = []
    polygon.forEach(function (el) {
      let M = mapboxgl.MercatorCoordinate.fromLngLat(el)
      res.push([M.x, M.y, M.z])
    })

    return res
  }

  drawChanged() {
    $(this).trigger('map.selectedAreaChanged')
  }

  triggerLazyPolygonVisibilityEvent(isVisible) {
    if (this._polygonVisible != isVisible) {
      //console.log("Triggering visibility changed evt")
      $(this).trigger('map.polygonVisibilityChanged', isVisible)
    }

    this._polygonVisible = isVisible
  }

  checkPolygonVisibility() {
    let isIn = true
    let P = this.getUserPolygon()

    if (P == null) {
      this.triggerLazyPolygonVisibilityEvent(isIn)
      return
    }

    let b = this._map.getBounds()

    P.forEach(function (i) {
      let lng = i[0]
      let lat = i[1]
      //console.log(b._sw.lng + ' <' + lng + '> ' + b._ne.lng);
      isIn &&= lng > b._sw.lng && lng < b._ne.lng && lat > b._sw.lat && lat < b._ne.lat
    })

    this.triggerLazyPolygonVisibilityEvent(isIn)
  }

  setStartCoords() {
    let P
    if (this._mapDraw != null) {
      P = this.getUserPolygon(true)
    } else {
      P = this._sitePolyGeoJSON
    }

    if (P == null) return

    let C = turf.centroid(P)
    this._startCoords = C.geometry.coordinates
  }

  goBackToPolygon(P) {
    let c = this._startCoords
    if (P != null) c = turf.centroid(P).geometry.coordinates
    this._map.flyTo({ center: c, zoom: this._defaultZoom })
  }

  setStartCoordsFromArrayCoords(arrayCoords) {
    let poly = turf.polygon([arrayCoords])
    let c = turf.centroid(poly).geometry.coordinates
    this._startCoords = c
    return c
  }

  setGeoLocation(locObj) {
    this._geoLocation = locObj
    if (locObj != null) {
      this._map.flyTo({ center: this._geoLocation.geoCoords, zoom: this._defaultZoom })
      $(this).trigger('map.locationChanged')
    }
  }

  onLoad() {
    // Insert the layer beneath any symbol layer.
    /* var layers = this._map.getStyle().layers;

        var labelLayerId;
        for (var i = 0; i < layers.length; i++) {
            if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                labelLayerId = layers[i].id;
                break;
            }
        }

        this._map.addLayer(
            this.my3DLayer,
            labelLayerId
        ); */

    this._map.setZoom(this._defaultZoom)
    this._map.setPitch(this._defaultPitch)

    this.enableLabels()
    if (this.hasSearchBox) SearchBoxControl.setEvents(this)
    if (this._areaCtrl != null) SelectedAreaControl.setEvents(this)

    this._map.getCanvas().style.width = '100%'
    this._map.getCanvas().style.height = '100%'
    this._map.resize()

    $(this).trigger('map.loaded')
  }

  destroy() {
    if (this._map == null) return
    this._map.remove()
    this._map = null
    this._mapDraw = null
  }

  enableLabels() {
    // use https://github.com/mapbox/cheap-ruler for fast measurements
    // depends on http://numeraljs.com/ and http://turfjs.org/getting-started
    // measurements source
    this._map.addSource('_measurements', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    // measurements layer
    this._map.addLayer({
      id: '_measurements',
      source: '_measurements',
      type: 'symbol',
      paint: {
        'text-color': 'hsl(234, 100%, 32%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1
      },
      layout: {
        'text-field': '{label}',
        'text-size': 9
      }
    })

    // on draw.render update the measurments
    let map = this
    this._map.on('draw.render', function () {
      map.labelDrawnPoly()
    })
  }

  labelDrawnPoly() {
    var labelFeatures = []
    let map = this
    var all = this._mapDraw?.getAll()
    if (all && all.features) {
      all.features.forEach(function (feature) {
        switch (turf.getType(feature)) {
          case 'Point':
            // label Points
            if (feature.geometry.coordinates.length > 1) {
              labelFeatures.push(
                turf.point(feature.geometry.coordinates, {
                  type: 'point',
                  label:
                    feature.geometry.coordinates[1].toFixed(6) +
                    ', ' +
                    feature.geometry.coordinates[0].toFixed(6)
                })
              )
            }
            break
          case 'LineString':
            /*
                        // label Lines
                        if (feature.geometry.coordinates.length > 1) {
                            var length = turf.length(feature);
                            //var label = numeral(length).format('0,0.0a') + 'm';
                            var label = `${(length/1000).toFixed(2)}m`
                            var midpoint = turf.along(feature, length/2)
                            ruler.along(feature.geometry.coordinates, length / 2);
                            labelFeatures.push(turf.point(midpoint, {
                                type: 'line',
                                label: label
                            }));
                        }
                        */
            break
          case 'Polygon': {
            if (
              feature.geometry.coordinates.length <= 0 ||
              feature.geometry.coordinates[0].length <= 3
            )
              break
            // label Polygons
            let lines = []
            for (let i = 0; i < feature.geometry.coordinates[0].length - 1; i++) {
              lines.push(
                turf.lineString([
                  feature.geometry.coordinates[0][i],
                  feature.geometry.coordinates[0][i + 1]
                ])
              )
            }

            lines.forEach(function (l) {
              let length = turf.length(l)

              //var label = numeral(length).format('0,0.0a') + 'm';
              var label
              let length_converted = UOMConv.conv(length * 1000, UOMConv.UMs.m.code, map.som)
              label = `${length_converted.value.toFixed(2)}${length_converted.label}`

              let midpoint = turf.along(l, length / 2)
              labelFeatures.push(
                turf.point(turf.getCoords(midpoint), {
                  type: 'line',
                  label: label
                })
              )
            })
            break
          }
        }
      })
    }
    this.refreshSourceFeatures('_measurements', labelFeatures)
  }

  switchSOM(targetSOM) {
    if (targetSOM != this.som) {
      this.som = targetSOM
      if (this._areaCtrl != null) SelectedAreaControl.refreshArea(this)
      this.labelDrawnPoly()
    }
  }

  setDrawPolyFromCoords(coords) {
    let poly = turf.polygon([coords])
    this._mapDraw.add(poly)

    SelectedAreaControl.refreshArea(this)
    return poly
  }

  addDrawControls() {
    this.removeDrawControls()

    this._mapDraw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      }
    })
    this._map.addControl(this._mapDraw)

    this._map.on('draw.create', () => {
      this.drawChanged()
    })

    this._map.on('draw.modechange', (evt) => {
      if (this._drawingCfg.only1poly) this.forceOnePolygon(evt)
    })

    this._map.on('draw.update', () => {
      this.drawChanged()
    })

    this._map.on('draw.delete', () => {
      this.drawChanged()
    })

    SelectedAreaControl.setEvents(this)
    return this
  }

  addRepositionControls(coords) {
    this.removeDrawControls()

    const poly = turf.polygon([coords])
    const center = turf.centroid(poly).geometry.coordinates

    this._map.flyTo({ center: center, zoom: 18, bearing: 0, pitch: 0 })

    this._mapDraw = new MapboxDraw({
      displayControlsDefault: false,
      styles: [
        {
          id: 'gl-draw-polygon-fill',
          type: 'fill',
          paint: {
            'fill-color': 'orange',
            'fill-outline-color': 'black',
            'fill-opacity': 0.5
          }
        }
      ]
    })

    this._map.addControl(this._mapDraw)

    this._repositionControls = new repositionControls(this._mapDraw)
    this._map.addControl(this._repositionControls, 'top-left')

    this._map.on('draw.update', () => {
      this._mapDraw.newCoords = this._mapDraw.getAll().features[0].geometry.coordinates[0]
    })
  }

  removeDrawControls() {
    if (this._mapDraw != null) {
      this._map.removeControl(this._mapDraw)
      this._mapDraw = null
    }

    return this
  }

  removeRepositionControls(newCoords = null, callback = () => {}) {
    if (this._mapDraw != null) {
      this._map.removeControl(this._mapDraw)
      this._map.removeControl(this._repositionControls)
      this._mapDraw = null
    }

    const center = newCoords
      ? turf.centroid(turf.polygon([newCoords])).geometry.coordinates
      : this._startCoords
    this._map.flyTo({ center, pitch: this._defaultPitch, zoom: this._defaultZoom, bearing: 0 })
    this._map.on('zoomend', callback)

    return this
  }

  init(cotainerId, configs) {
    this.hasSearchBox = false
    let self = this
    if (this._map != null) this.destroy()
    if (this._startCoords == null) this._startCoords = [-0.1281, 51.508]
    this._defaultZoom = 16.5
    this._defaultPitch = 30

    this._drawingCfg = configs.DRAWING

    this._buildingShown = false

    if (global.offline) {
      Object.assign(this, mockMap)
      console.debug('OFFLINE - triggering map loaded event')
      $(this).trigger('map.loaded')
      return
    }

    mapboxgl.accessToken = this._token
    this._map = new mapboxgl.Map({
      style:
        theme == 'light' ? 'mapbox://styles/mapbox/streets-v12' : 'mapbox://styles/mapbox/dark-v10',
      center: this._startCoords,
      zoom: 15.5,
      pitch: 45,
      bearing: 0,
      container: cotainerId,
      antialias: true,
      preserveDrawingBuffer: true
    })

    this._map.on('style.load', () => {
      // Insert the layer beneath any symbol layer.
      const layers = self._map.getStyle().layers
      const labelLayerId = layers.find(
        (layer) => layer.type === 'symbol' && layer.layout['text-field']
      ).id

      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      self._map.addLayer(self.layer3dbuildings, labelLayerId)
      self._map.setLayoutProperty(self.layer3dbuildings.id, 'visibility', 'none')
    })

    if (configs.HIDE_SEARCHBOX != true) {
      this._map.addControl(new SearchBoxControl(), 'top-right')
      this.hasSearchBox = true
    }

    this._mapNav = new mapboxgl.NavigationControl()
    this._map.addControl(this._mapNav)

    if (this._drawingCfg.enabled) {
      this.addDrawControls()

      let self = this
      this._map.on('moveend', function (evt) {
        if (self._drawingCfg.poly5vertexes) self.checkPolygonVisibility(evt)
      })
    }

    this._areaCtrl = new SelectedAreaControl()
    this._map.addControl(this._areaCtrl, 'top-left')
    this._map.addControl(new ToggleBuildingsControl(), 'top-left')

    this._map.on('load', function (evt) {
      self.onLoad(evt)
    })
    this._map.on('dataloading', function () {
      $(self).trigger('map.dataloading')
    })
  }
}

const mockMap = {
  drawSitePolygonFromCoords() {
    return this
  },

  addDrawControls() {
    return this
  },

  removeSitePolygon() {
    return this
  },

  setDrawPolyFromCoords() {
    return this
  },

  removeDrawControls() {
    return this
  }
}

async function geoSearch(searchText, callback) {
  if (searchText.trim() == '')
    return addMessage({ text: MESSAGES.MAP_LOCATE_ERR_EMPTYSTRING, color: 'error' })

  const url = `map/locate/${searchText}`
  try {
    const { data } = await pApiClient.get(url, { suffix: 's/bldg/' })
    if (data.features.length == 0) {
      addMessage({ id: url, text: MESSAGES.MAP_LOCATE_NO_RESULTS, color: 'warning' })
      callback(null)
      return
    }
    callback(new LocationClass(data))
  } catch (e) {
    addMessage({ id: url, text: MESSAGES.MAP_LOCATE_GENERAL_FAILURE, color: 'error' })
  }
}

export { pMapClass, mapLySrcRef }
