import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { pApiClient } from '@/library/api'
import Material from '@/library/models/Material'
import { useAssemblyStore } from './assembly'
import { useMessenger } from '@/composables/messenger'
import { MESSAGES } from '@/library/messages'

const { addMessage } = useMessenger()

export const useMaterialStore = defineStore('material', () => {
  const loading = ref(false)
  const materials = ref([])

  /**
   * @param {number} id
   * @returns {Promise<Material>}
   */
  async function fetchMaterial(id) {
    loading.value = true
    const url = 'material/' + id
    try {
      const res = await pApiClient.get(url)
      return new Material(res)
    } catch (err) {
      addMessage({ id: url, text: MESSAGES.MATERIAL_LOAD_ERROR, color: 'error' })
    }
    loading.value = false
  }

  /**
   * @returns {Promise<void>}
   */
  async function fetchMaterials(reload = false) {
    if (materials.value.length && !reload) return
    if (loading.value) return

    loading.value = true

    const url = 'material/'
    try {
      const { data } = await pApiClient.get(url)
      data.sort((a, b) => a.name.localeCompare(b.name))
      materials.value = data.map((material) => new Material(material))
    } catch (err) {
      addMessage({ id: url, text: MESSAGES.MATERIAL_LIST_ERROR, color: 'error' })
    }
    loading.value = false
  }

  /**
   * @param {Material} material
   * @returns {Promise<void>}
   */
  async function saveMaterial(material) {
    const id = material.id
    const method = id ? 'put' : 'post'
    const url = id ? 'material/' + id : 'material'

    loading.value = true
    try {
      await pApiClient[method](url, material)
      loading.value = false
      addMessage({ id: url, text: MESSAGES.MATERIAL_SAVE_SUCCESS, color: 'success' })
      await fetchMaterials(true)
    } catch (err) {
      addMessage({ id: url, text: MESSAGES.MATERIAL_SAVE_ERROR, color: 'error' })
      loading.value = false
    }
  }

  /**
   * @param {number} id
   * @returns {Promise<void>}
   */
  async function deleteMaterial(id) {
    loading.value = true

    if (await isMaterialInUse(id)) {
      addMessage({
        title: MESSAGES.MATERIAL_DELETE_ERROR,
        text: 'This material is being used in an assembly.',
        color: 'error'
      })
      loading.value = false
      return
    }

    try {
      await pApiClient.delete('material/' + id)
      loading.value = false
      addMessage({ text: MESSAGES.MATERIAL_DELETE_SUCCESS, color: 'success' })
      await fetchMaterials(true)
    } catch (err) {
      addMessage({ text: MESSAGES.MATERIAL_DELETE_ERROR, color: 'error' })
      loading.value = false
    }
  }

  /**
   * @param {number} id
   * @returns {Promise<boolean>}
   */
  async function isMaterialInUse(id) {
    const assemblyStore = useAssemblyStore()
    const { assemblies } = storeToRefs(useAssemblyStore())
    await assemblyStore.fetchAssemblies()

    return assemblies.value.some(({ components }) =>
      components.some(({ material }) => material.id === id)
    )
  }

  return {
    loading,
    materials,
    fetchMaterial,
    fetchMaterials,
    saveMaterial,
    deleteMaterial
  }
})
