import { defineStore } from 'pinia'
import { ref } from 'vue'
import { pApiClient } from '@/library/api'
import Assembly from '@/library/models/Assembly'
import { useMessenger } from '@/composables/messenger'
import { MESSAGES } from '@/library/messages'

const { addMessage } = useMessenger()

export const useAssemblyStore = defineStore('assembly', () => {
  const loading = ref(false)
  const assemblies = ref([])

  /**
   *
   * @param {number} id
   * @returns {Promise<Assembly>}
   */
  async function fetchAssembly(id) {
    loading.value = true
    const url = 'assembly/' + id
    try {
      const { data } = await pApiClient.get(url)
      return new Assembly(data)
    } catch (err) {
      addMessage({ id: url, text: MESSAGES.ASSEMBLY_LOAD_ERROR, color: 'error' })
    } finally {
      loading.value = false
    }
  }

  /**
   * @returns {Promise<void>}
   */
  async function fetchAssemblies(reload = false) {
    if (assemblies.value.length && !reload) return
    if (loading.value) return

    loading.value = true
    const url = '/assembly'
    try {
      const { data } = await pApiClient.get(url)
      data.sort((a, b) => a.name.localeCompare(b.name))
      assemblies.value = data.map((assembly) => new Assembly(assembly))
    } catch (err) {
      addMessage({ id: url, text: MESSAGES.ASSEMBLY_LIST_ERROR, color: 'error' })
    } finally {
      loading.value = false
    }
  }

  /**
   * @param {Assembly} assembly
   * @returns {Promise<void>}
   */
  async function saveAssembly(assembly) {
    const id = assembly.id
    const method = id ? 'put' : 'post'
    const url = id ? 'assembly/' + id : 'assembly'

    loading.value = true
    await pApiClient[method](url, assembly)
    loading.value = false
    await fetchAssemblies(true)
  }

  /**
   * @param {number} id
   * @returns {Promise<void>}
   */
  async function deleteAssembly(id) {
    loading.value = true
    const url = 'assembly/' + id
    try {
      await pApiClient.delete(url)
      loading.value = false
      addMessage({ id: url, text: MESSAGES.ASSEMBLY_DELETE_SUCCESS, color: 'success' })
      await fetchAssemblies(true)
    } catch (err) {
      addMessage({ id: url, text: MESSAGES.ASSEMBLY_DELETE_ERROR, color: 'error' })
      loading.value = false
    }
  }

  function filteredAssemblies(isRetrofit) {
    return isRetrofit
      ? assemblies.value
      : assemblies.value.filter(({ is_retrofit }) => !is_retrofit)
  }

  return {
    loading,
    assemblies,
    filteredAssemblies,
    fetchAssembly,
    fetchAssemblies,
    saveAssembly,
    deleteAssembly
  }
})
