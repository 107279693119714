<template>
  <v-snackbar
    v-for="(message, index) in messages"
    :key="message.id"
    v-model="message.show"
    multi-line
    vertical
    :color="message.color"
    :timeout="message.timeout"
    :style="`transform: scale(${1 - (messages.length - index) / 50}); z-index: 1000${index}`"
  >
    <div class="font-weight-bold text-subtitle-1">
      <span> {{ message.title }} </span>
    </div>

    <div class="text-subtitle-2" v-html="message.text" />

    <div v-if="message.details">
      <v-btn
        v-if="!showDetails"
        variant="text"
        density="compact"
        size="small"
        class="px-0 mt-2 text-body-2"
        append-icon="arrow_drop_down"
        @click="showDetails = true"
      >
        Show details
      </v-btn>

      <div v-else class="mt-4">
        <pre>{{ message.details }}</pre>
      </div>
    </div>

    <template #actions>
      <v-btn variant="text" text="Close" @click="removeMessage(index)" />
      <v-btn
        v-if="message.action.text"
        class="font-weight-bold"
        variant="text"
        :to="message.action.to"
        :text="message.action.text"
        @click="
          message.action.callback
            ? callbackAndRemoveMessage(message.action.callback, index)
            : removeMessage(index)
        "
      />
    </template>
  </v-snackbar>
</template>

<script setup>
import { ref } from 'vue'
import { useMessenger } from '@/composables/messenger'

const { messages, removeMessage } = useMessenger()

const showDetails = ref(false)

function callbackAndRemoveMessage(callback, index) {
  callback()
  removeMessage(index)
}
</script>
