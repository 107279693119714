<template>
  <v-navigation-drawer expand-on-hover rail color="surface-5" class="pt-1" permanent>
    <v-list>
      <v-list-item
        prepend-icon="home_pin"
        :to="'/project/' + currentProject.id"
        :disabled="!tabs.project"
        value="project"
      >
        <v-list-item-title class="font-weight-bold text-overline">
          Project Details
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        id="compareTab"
        prepend-icon="domain_add"
        :to="'/compare/' + currentProject.id"
        :disabled="!tabs.compare"
        value="compare"
      >
        <v-list-item-title class="font-weight-bold text-overline">
          Compare Designs
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        prepend-icon="architecture"
        :to="'/liveediting/' + currentProject.id"
        :disabled="!tabs.liveediting"
        value="liveediting"
      >
        <v-list-item-title class="font-weight-bold text-overline"> Live Editing </v-list-item-title>
      </v-list-item>
      <v-list-item
        prepend-icon="query_stats"
        :to="'/data/' + currentProject.id"
        :disabled="!tabs.data"
        value="data"
      >
        <v-list-item-title class="font-weight-bold text-overline">
          Carbon Tracker
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        prepend-icon="search_insights"
        :to="'/detailedresults/' + currentProject.id"
        :disabled="!tabs.data"
        value="detailedresults"
      >
        <v-list-item-title class="font-weight-bold text-overline">
          Detailed Results
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { useLiveediting } from '@/composables/liveediting'
import { useProjectStore } from '@/stores/project'
import { storeToRefs } from 'pinia'

export default {
  name: 'ProjectLeftSideBar',

  setup() {
    const { currentProject, tabs } = storeToRefs(useProjectStore())
    const { selectedBuilding } = useLiveediting()

    return {
      currentProject,
      tabs,
      selectedBuilding
    }
  },

  unmounted() {
    this.selectedBuilding = null
  }
}
</script>
