<template>
  <v-dialog v-model="show" max-width="450px">
    <v-card>
      <v-card-title class="px-6 py-3"> Add a dataset </v-card-title>

      <v-card-text>
        <v-alert
          v-if="hasError === 'general'"
          type="error"
          variant="tonal"
          icon="error"
          density="compact"
          class="mb-4"
        >
          Something went wrong, please try again.
        </v-alert>

        <v-form>
          <v-text-field v-model="newDataset.name" label="Name" />
          <v-text-field
            v-model="newDataset.shortcode"
            label="Shortcode"
            :error-messages="
              hasError === 'shortcode' ? ['Shortcode already exists, please choose another'] : []
            "
          />
          <v-select v-model="newDataset.region" :items="regions" label="Region" />
          <v-select
            v-model="newDataset.lca_methodology"
            :items="methodologies"
            label="LCA Methodology"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn color="secondary" @click="show = false"> Close </v-btn>
        <v-btn color="success" @click="modalSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useDatasetStore } from '@/stores/dataset'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

export default {
  props: {
    value: {
      type: Boolean
    }
  },

  emits: ['update:modelValue', 'new-dataset'],

  setup() {
    const { regions, methodologies, saveDataset } = useDatasetStore()
    const { newDataset, isEditingDataset } = storeToRefs(useDatasetStore())

    const hasError = ref('')

    return {
      regions,
      methodologies,
      newDataset,
      isEditingDataset,
      saveDataset,
      hasError
    }
  },

  computed: {
    modalTitle() {
      return this.isEditingDataset ? 'Edit dataset' : 'Create dataset'
    },

    show: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },

  methods: {
    async modalSave() {
      this.hasError = ''
      try {
        const id = await this.saveDataset()
        this.$emit('new-dataset', id)
        this.show = false
      } catch (error) {
        if (error.response?.data?.shortcode) {
          this.hasError = 'shortcode'
        } else {
          this.hasError = 'general'
        }
      }
    }
  }
}
</script>
