// TODO: Remove dependency on GenDesignManager intialization
// eslint-disable-next-line no-unused-vars

import { useMessenger } from '@/composables/messenger'
import axios from '@/library/axios'

const { addMessage } = useMessenger()

const pApiClient = {
  /**
   * @param {string} url
   * @param {object} [config={}]
   * @returns {Promise}
   */
  async get(url, config = null) {
    try {
      return await axios.get(url, config)
    } catch (error) {
      addMessage({
        id: url,
        text: 'Something went wrong. Please try again',
        color: 'error',
        details: error.response.data
      })
      throw error
    }
  },

  /**
   * @param {string} url
   * @param {object} data
   * @returns {Promise}
   */
  async post(url, data, config = null) {
    try {
      return await axios.post(url, data, config)
    } catch (error) {
      addMessage({
        id: url,
        text: 'Something went wrong. Please try again',
        color: 'error',
        details: error.response.data
      })
      throw error
    }
  },

  /**
   * @param {string} url
   * @param {object} data
   * @returns {Promise}
   */
  async put(url, data, config = null) {
    try {
      return await axios.put(url, data, config)
    } catch (error) {
      addMessage({
        id: url,
        text: 'Something went wrong. Please try again',
        color: 'error',
        details: error.response.data
      })
      throw error
    }
  },

  /**
   * @param {string} url
   * @param {object} data
   * @returns {Promise}
   */
  async patch(url, data, config = null) {
    try {
      return await axios.patch(url, data, config)
    } catch (error) {
      addMessage({
        id: url,
        text: 'Something went wrong. Please try again',
        color: 'error',
        details: error.response.data
      })
      throw error
    }
  },

  /**
   * @param {string} url
   * @returns {Promise}
   */
  async delete(url, config = null) {
    try {
      return await axios.delete(url, config)
    } catch (error) {
      addMessage({
        id: url,
        text: 'Something went wrong. Please try again',
        color: 'error',
        details: error.response.data
      })
      throw error
    }
  }
}

export { pApiClient }
