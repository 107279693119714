import { BuildingElements, FootprintTypes, LCAStagesGroups } from '@/library/enums'
import { pBlock } from '@/library/liveediting'
import { ConfigsManager } from '@/library/settings'
import { UOMConv } from '@/library/utils'
import * as turf from '@turf/turf'
import { BuildingBlock } from '@/library/models/BuildingBlock'
import { LCAStages } from '@/library/models/LCAStages'
import { LCABuildingElements } from './LCABuildingElements'
import { DesignConstraints } from './DesignConstraints'

/** @typedef {import('@/library/models/Material').default} Material */

export class Building {
  _prjRef

  /** @type {number} */
  id

  /** @type {'D'|'C'|'R'} */
  status

  /** @type {string|null} @maxlength 200 */
  label

  /** @type {string} @readonly */
  project

  /** @type {'OFFICE'|'WAREHOUSE'|'RESIDENTIAL'|'HEALTHCARE'|'RETAIL'|'EDUCATIONAL'|'MULTICARPARK'} */
  type = 'OFFICE'

  /** @description Wind speed [m/s] @type {number} @min 35 @max 65 @default 45 */
  wind_speed

  lci_dataset = 'RICSv2_UK'

  /** @description Lifeycle assessment boundary (EN15978) @type {'A1_A3'|'A1_A5'|'A_C'|'B_C'|'A_D'} */
  lca_assesment = 'A_C'

  /** @type {string} */
  lateral_system_name

  /** @description Heating system @type {'GAS_BOILER'|'ASHP'|'VRF'|'DELECHEATING'|'EAHP'|'COMGB_ASHP'|'COM_ASHP'|'COM_HEAT_POWER'} */
  heating_sys

  /** @description Cooling system @type {'CHILLER' | 'NONE'} */
  coolingsys

  get cooling_sys() {
    return this.coolingsys
  }

  set cooling_sys(v) {
    this.coolingsys = v
    if (v === null) {
      this.cooling_capacity = null
    } else {
      this.cooling_capacity = 50
    }
  }

  /** @description Cooling system capacity [kW] @type {number} @min 50 @max 500 */
  cooling_capacity

  /** @description Energy use intensity [kWh/m2.yr] @type {number} @min 0 @max 2500 */
  EUI = 50

  /** @description Percent of EUI served by electricity @type {number} @min 0 @max 100 */
  electricity_share_perc = 100

  /** @description Electrical grid decarbonization scenario @type {'UK_NON_DECARBONISED_GRID'|'UK_DECARBONISED_GRID'|'US_MID_GRID'|'US_DECARONISED_GRID'} */
  grid_decarbon_scenario

  grid_coefficient_decarb

  grid_nondecarb_scenario

  grid_coefficient_nondecarb

  /** @description Interstorey height of underground storeys [m] @type {number} @min 3 @max 5 */
  UG_ish = 3.5

  /** @description Number of underground storeys @type {number} @min 0 @max 3 */
  UG_storeys = 0

  /** @type {number} */
  EC_A1A3_CO2KG = 0
  EC_A1A3_biogenic_CO2KG = 0
  EC_A4_CO2KG = 0
  EC_A5_1_CO2KG = 0
  EC_A5_2_CO2KG = 0
  EC_A5_3_CO2KG = 0
  EC_B1_1_CO2KG = 0
  EC_B1_2_CO2KG = 0
  EC_B2_CO2KG = 0
  EC_B3_CO2KG = 0
  EC_B4_CO2KG = 0
  EC_C1_CO2KG = 0
  EC_C2_CO2KG = 0
  EC_C3_CO2KG = 0
  EC_C4_CO2KG = 0
  EC_D1_CO2KG = 0
  EC_A1A3_SEQ_CO2KG = 0

  /** @type {number} */
  OC_B4_CO2KG = 0

  /** @type {number} */
  OC_B6_CO2KG = 0

  /** @type {number} */
  OC_B7_CO2KG = 0

  /** @type {Array<BuildingBlock>} */
  blocks = []

  /** @type {number} */
  _GFA

  /** @type {Array<{material: string, mass_qty: number}>} */
  QTOLateralSys = null

  /** @type {Array<Material>} */
  materials = []

  /** @type {BuildingBlock} */
  root

  lighting_sys
  ventilation_sys

  /** @type {number} */
  heating_capacity

  /** @type {number} */
  lighting_capacity

  /** @type {number} */
  ventilation_capacity

  /** @type {'GENFOUNDATIONS'|'SHALLOWFOUNDATION'|'DEEPFOUNDATION'} @default "GENFOUNDATIONS" */
  foundation_type = 'GENFOUNDATIONS'

  /** @type {'CLAY'|'SAND'|'GRAVEL'} @default "CLAY" */
  soil_type = 'GRAVEL'

  /** @type {Object} */
  windows = { items: [], base_height_list: [], height_list: [] }

  /** @type {number} */
  ffe_allowance_perc = 5

  /** @type {number} */
  external_works_allowance_perc = 5

  /** @type {number} */
  prev_demo_GIA = 0

  /** @type {number} */
  GIA

  /** @type {number} */
  RSP = 60

  /** @type {string} */
  RatingSCORS

  /** @type {string} */
  RatingLETI_Lifecycle

  /** @type {number} */
  foundation_strengthening_perc = 100

  /** @type {number} */
  lateral_strengthening_perc = 100

  foundation_depth
  foundation_width
  foundation_instances
  ug_beam_depth
  ug_beam_width
  ug_beam_instances
  ug_beam_sec_instances
  ug_beam_reinf_ratio
  ug_col_depth
  ug_col_width
  ug_col_instances
  ug_col_reinf_ratio
  ug_floor_depth
  ug_slab_reinf_ratio

  get ugBeamDimensions() {
    if (!this.ug_beam_width && !this.ug_beam_depth) return 'n/a'
    return `${this.ug_beam_width} x ${this.ug_beam_depth} mm`
  }

  get ugColumnDimensions() {
    if (!this.ug_col_width && !this.ug_col_depth) return 'n/a'
    return `${this.ug_col_width} x ${this.ug_col_depth} mm`
  }

  get fdnDimensions() {
    if (!this.foundation_width && !this.foundation_depth) return 'n/a'
    return `${this.foundation_width} x ${this.foundation_depth} mm`
  }

  systems_A1A3_kgCO2m2 = 0
  systems_A4_kgCO2m2 = 0
  systems_A5_3_kgCO2m2 = 0
  systems_C2_kgCO2m2 = 0
  systems_C3_kgCO2m2 = 0
  systems_C4_kgCO2m2 = 0
  systems_D1_kgCO2m2 = 0

  onsite_A1A3_kgCO2m2 = 0
  onsite_A4_kgCO2m2 = 0
  onsite_A5_3_kgCO2m2 = 0
  onsite_C2_kgCO2m2 = 0
  onsite_C3_kgCO2m2 = 0
  onsite_C4_kgCO2m2 = 0
  onsite_D1_kgCO2m2 = 0

  electrical_A1A3_kgCO2m2 = 0
  electrical_A4_kgCO2m2 = 0
  electrical_A5_3_kgCO2m2 = 0
  electrical_C2_kgCO2m2 = 0
  electrical_C3_kgCO2m2 = 0
  electrical_C4_kgCO2m2 = 0
  electrical_D1_kgCO2m2 = 0

  ventilation_A1A3_kgCO2m2 = 0
  ventilation_A4_kgCO2m2 = 0
  ventilation_A5_3_kgCO2m2 = 0
  ventilation_C2_kgCO2m2 = 0
  ventilation_C3_kgCO2m2 = 0
  ventilation_C4_kgCO2m2 = 0
  ventilation_D1_kgCO2m2 = 0

  plumbing_A1A3_kgCO2m2 = 0
  plumbing_A4_kgCO2m2 = 0
  plumbing_A5_3_kgCO2m2 = 0
  plumbing_C2_kgCO2m2 = 0
  plumbing_C3_kgCO2m2 = 0
  plumbing_C4_kgCO2m2 = 0
  plumbing_D1_kgCO2m2 = 0

  get plumbing_kgCO2m2() {
    return {
      A1A3: this.plumbing_A1A3_kgCO2m2,
      A4: this.plumbing_A4_kgCO2m2,
      A5_3: this.plumbing_A5_3_kgCO2m2,
      C2: this.plumbing_C2_kgCO2m2,
      C3: this.plumbing_C3_kgCO2m2,
      C4: this.plumbing_C4_kgCO2m2,
      D1: this.plumbing_D1_kgCO2m2
    }
  }

  get electrical_kgCO2m2() {
    return {
      A1A3: this.electrical_A1A3_kgCO2m2,
      A4: this.electrical_A4_kgCO2m2,
      A5_3: this.electrical_A5_3_kgCO2m2,
      C2: this.electrical_C2_kgCO2m2,
      C3: this.electrical_C3_kgCO2m2,
      C4: this.electrical_C4_kgCO2m2,
      D1: this.electrical_D1_kgCO2m2
    }
  }

  get onsite_kgCO2m2() {
    return {
      A1A3: this.onsite_A1A3_kgCO2m2,
      A4: this.onsite_A4_kgCO2m2,
      A5_3: this.onsite_A5_3_kgCO2m2,
      C2: this.onsite_C2_kgCO2m2,
      C3: this.onsite_C3_kgCO2m2,
      C4: this.onsite_C4_kgCO2m2,
      D1: this.onsite_D1_kgCO2m2
    }
  }

  get other_systems_kgCO2m2() {
    return {
      A1A3: this.systems_A1A3_kgCO2m2,
      A4: this.systems_A4_kgCO2m2,
      A5_3: this.systems_A5_3_kgCO2m2,
      C2: this.systems_C2_kgCO2m2,
      C3: this.systems_C3_kgCO2m2,
      C4: this.systems_C4_kgCO2m2,
      D1: this.systems_D1_kgCO2m2
    }
  }

  get ventilation_kgCO2m2() {
    return {
      A1A3: this.ventilation_A1A3_kgCO2m2,
      A4: this.ventilation_A4_kgCO2m2,
      A5_3: this.ventilation_A5_3_kgCO2m2,
      C2: this.ventilation_C2_kgCO2m2,
      C3: this.ventilation_C3_kgCO2m2,
      C4: this.ventilation_C4_kgCO2m2,
      D1: this.ventilation_D1_kgCO2m2
    }
  }

  get sortedBlocks() {
    return this.blocks.sort((a, b) => a.parent - b.parent)
  }

  get isMultiblock() {
    return this.blocks.length > 1
  }

  get UG_ish_usrUM() {
    return UOMConv.convLengthFromBase(this.UG_ish, this._prjRef.som)
  }
  set UG_ish_usrUM(v) {
    this.UG_ish = UOMConv.convLengthToBase(v, this._prjRef.som)
  }

  get site_lonlat_centroid() {
    return turf.centroid(turf.polygon([this._prjRef.location_coords])).geometry.coordinates
  }

  get EC_CO2KG() {
    let res = new LCAStages()
    if (this.EC_A1A3_CO2KG == undefined) return res

    res.A1A3 = this.EC_A1A3_CO2KG
    res.A1A3_biogenic = this.EC_A1A3_biogenic_CO2KG || this.EC_A1A3_SEQ_CO2KG
    res.A4 = this.EC_A4_CO2KG
    res.A5_1 = this.EC_A5_1_CO2KG
    res.A5_2 = this.EC_A5_2_CO2KG
    res.A5_3 = this.EC_A5_3_CO2KG
    res.B1_1 = this.EC_B1_1_CO2KG
    res.B1_2 = this.EC_B1_2_CO2KG
    res.B2 = this.EC_B2_CO2KG
    res.B3 = this.EC_B3_CO2KG
    res.B4 = this.EC_B4_CO2KG
    res.C1 = this.EC_C1_CO2KG
    res.C2 = this.EC_C2_CO2KG
    res.C3 = this.EC_C3_CO2KG
    res.C4 = this.EC_C4_CO2KG
    res.D1 = this.EC_D1_CO2KG

    return res
  }
  set EC_CO2KG(value) {
    this.EC_A1A3_CO2KG = value.A1A3
    this.EC_A1A3_biogenic_CO2KG = value.A1A3_biogenic
    this.EC_A4_CO2KG = value.A4
    this.EC_A5_1_CO2KG = value.A5_1
    this.EC_A5_2_CO2KG = value.A5_2
    this.EC_A5_3_CO2KG = value.A5_3
    this.EC_B1_1_CO2KG = value.B1_1
    this.EC_B1_2_CO2KG = value.B1_2
    this.EC_B2_CO2KG = value.B2
    this.EC_B3_CO2KG = value.B3
    this.EC_B4_CO2KG = value.B4
    this.EC_C1_CO2KG = value.C1
    this.EC_C2_CO2KG = value.C2
    this.EC_C3_CO2KG = value.C3
    this.EC_C4_CO2KG = value.C4
    this.EC_D1_CO2KG = value.D1
  }

  get EC_CO2KGm2() {
    return this.EC_CO2KG.divide(this.GIA)
  }

  get OC_B6_CO2KGm2() {
    if (this.OC_B6_CO2KG == undefined) return 0
    return this.OC_B6_CO2KG / this.GIA
  }

  get OC_B7_CO2KGm2() {
    if (this.OC_B7_CO2KG == undefined) return 0
    return this.OC_B7_CO2KG / this.GIA
  }

  get WLC() {
    return this.OC_B6_CO2KG + this.OC_B7_CO2KG + this.EC_CO2KG.total()
  }

  get WLCm2() {
    return this.WLC / this.GIA
  }

  get WWR() {
    return this.blocks.reduce((sum, { WWR_perc }) => sum + WWR_perc, 0) / this.blocks.length
  }

  get prev_demo_GIA_som() {
    return Math.round(this._prjRef.som == 'M' ? this.prev_demo_GIA : this.prev_demo_GIA * 10.76395)
  }

  set prev_demo_GIA_som(v) {
    this.prev_demo_GIA = this._prjRef.som == 'M' ? v : v / 10.76395
  }

  get GFA() {
    const rootBlock = this.blocks.find((b) => b.parent == null)
    const belowGround = rootBlock ? rootBlock.footprint_area * this.UG_storeys : 0
    const aboveGround = this.blocks.reduce((prev, x) => prev + x.GFA, 0)
    return aboveGround + belowGround
  }

  set GFA(v) {
    this._GFA = v
  }
  /**
   * @returns {Number}
   */
  get height() {
    if (this.blocks.length == 0) return 0
    if (this.blocks.length == 1) return this.blocks[0].height

    let parentIDs = this.blocks.filter((b) => b.parent != null).map((b) => b.parent)
    let leaves = this.blocks.filter((b) => !parentIDs.includes(b.id))

    let getAltitude = (b) => {
      let parentH =
        b.parent == null ? 0 : getAltitude(this.blocks.find((block) => block.id == b.parent))

      return parentH + b.height
    }

    let altitudes = [0]
    for (let leaf of leaves) {
      altitudes.push(getAltitude(leaf))
    }

    return Math.max(...altitudes)
  }

  get compactness() {
    const result = this.blocks.reduce(
      (prev, block, index) => {
        const { facade_SurfaceArea, height, footprint_area } = block
        const footprint = index == 0 ? footprint_area : 0
        return {
          surface: prev.surface + facade_SurfaceArea + footprint,
          internal_volume: prev.internal_volume + footprint_area * height
        }
      },
      { surface: 0, internal_volume: 0 }
    )
    return result.surface / result.internal_volume
  }

  get structural_system() {
    let uniqueRes = []
    this.blocks.forEach((x) => {
      if (!uniqueRes.includes(x.structural_system)) {
        uniqueRes.push(x.structural_system)
      }
    })
    return uniqueRes
  }
  get facade_system() {
    let uniqueRes = []
    this.blocks.forEach((x) => {
      if (!uniqueRes.includes(x.facade_system)) {
        uniqueRes.push(x.facade_system)
      }
    })
    return uniqueRes
  }
  get roof_system() {
    let uniqueRes = []
    this.blocks.forEach((x) => {
      if (!uniqueRes.includes(x.roof_system)) {
        uniqueRes.push(x.roof_system)
      }
    })
    return uniqueRes
  }
  get footprintType() {
    return this.getRootBlock().footprintType
  }

  get costs() {
    let res = {
      tot_cost: Math.floor(
        this._prjRef.site_area_usrUoM * this._prjRef.cost_of_land + this._prjRef.construction_cost
      ),
      tot_revenue: Math.floor(this._prjRef.selling_price),
      profit: 0
    }

    res.profit = res.tot_revenue - res.tot_cost

    return res
  }

  /**
   * @param {any} mep
   */
  set MEP_params(mep) {
    Object.assign(this, mep)
  }

  /**
   * @param {any} oe
   */
  set OE(oe) {
    Object.assign(this, oe)
  }

  /**
   * @param {{ ISH: any; num_storeys: number; }} ug
   */
  set UG(ug) {
    if (ug == null) {
      this.UG_ish = 3.5
      this.UG_storeys = 0
      return
    }
    this.UG_ish = ug.ISH
    this.UG_storeys = ug.num_storeys
  }

  get UG() {
    return {
      ISH: this.UG_ish,
      num_storeys: this.UG_storeys
    }
  }

  constructor(prj, data = {}, initBlocks = false) {
    this._prjRef = prj
    this.project = prj.id
    this.type = prj.building_type
    Object.assign(this, data)
    this.blocks = this.blocks.map((b) => Object.assign(new BuildingBlock(this._prjRef), b))
    if (initBlocks) this.initBlocks()
  }

  static newDraftBuilding(project, blocks) {
    return new Building(
      project,
      {
        blocks: blocks.map((block, index) => ({
          id: block.id,
          label: 'Block ' + index,
          parent: block.parent,
          geom_details: { ...block, footprintType: FootprintTypes.CUSTOM },
          design_constraints: new DesignConstraints()
        })),
        lateral_system_name: 'CONCRETE_SHEARWALL',
        MEP_params: {
          heating_sys: 'ASHP',
          cooling_sys: null,
          lighting_sys: null,
          ventilation_sys: null,
          heating_capacity: null,
          cooling_capacity: 100,
          lighting_capacity: null,
          ventilation_capacity: null,
          external_works_allowance_perc: 5,
          ffe_allowance_perc: 5
        },
        OE: {
          EUI: 50,
          electricity_share_perc: 100,
          grid_decarbon_scenario: 'UK_DECARBONISED_GRID',
          grid_coefficient_decarb: 0,
          grid_nondecarb_scenario: 'UK_NON_DECARBONISED_GRID',
          grid_coefficient_nondecarb: 0
        },
        UG: {
          num_storeys: 0,
          ISH: 3.5
        },
        status: 'D',
        label: '[DRAFT]',
        id: -1
      },
      true
    )
  }

  updateFromLCA(lcaData) {
    this.OC_B6_CO2KG = lcaData.OC_B6_CO2KG
    this.OC_B7_CO2KG = lcaData.OC_B7_CO2KG
    this.EC_CO2KG = lcaData.EC_CO2KG
    this.materials = lcaData.materials
    this.GIA = lcaData.GIA
    this.blocks = lcaData.blocks.map((b) => Object.assign(new BuildingBlock(this._prjRef), b))
  }

  refreshQTOLateral() {
    this.QTOLateralSys = this.materials
      .filter((item) => item.building_element == BuildingElements.LateralSystem)
      .map((mat) => {
        return {
          material: mat.material,
          mass_qty: mat.mass_qty
        }
      })
  }

  /**
   *
   * @returns {BuildingBlock}
   */
  getRootBlock() {
    if (this.blocks.length == 0) return new BuildingBlock('')
    return this.blocks.find((i) => i.parent == null)
  }

  initBlocks() {
    let buildingObj = this

    let variableLoad = this.getVariableLoadDefault(this.type)
    this.blocks.forEach((block) => {
      if (block.variable_load == null) block.variable_load = variableLoad
    })

    let root = this.blocks.find((i) => i.parent == null)

    let b = new pBlock(
      root.num_storey,
      root.interstorey_height,
      turf.polygon([root.footprint_lnglat_coords]),
      root.id
    )
    b.color = ConfigsManager.getWallSettings(root.facade_system).color
    b.data = root
    this.root = b

    let getChildrenFunc = (block) => {
      return buildingObj.blocks
        .filter((c) => c.parent == block.id)
        .map((i) => {
          let newB = new pBlock(
            i.num_storey,
            i.interstorey_height,
            turf.polygon([i.footprint_lnglat_coords]),
            i.id
          )
          newB.color = ConfigsManager.getWallSettings(i.facade_system).color
          newB.data = i
          return newB
        })
    }

    this.buildRelation((x) => x, b, getChildrenFunc)
  }

  getVariableLoadDefault(buildingType) {
    switch (buildingType) {
      case 'WAREHOUSE':
        return 2.0
      case 'RESIDENTIAL':
      case 'CARPARK':
        return 2.5
      case 'SCHOOL':
      case 'HOSPITAL':
      case 'OFFICE':
        return 3.0
      case 'RETAIL':
        return 4.0
    }
  }

  buildRelation(mkBlockFunc, sourceBlock, getChildrenFunc = null, newParent = null) {
    let block = mkBlockFunc(sourceBlock)
    block.setParent(newParent)
    let children = sourceBlock.children
    if (getChildrenFunc != null) {
      children = getChildrenFunc(sourceBlock)
    }
    for (let child of children) {
      this.buildRelation(mkBlockFunc, child, getChildrenFunc, block)
    }
    return block
  }

  /**
   *
   * @param {Building} compareTo
   */
  compareLateralSysParams(compareTo) {
    const LSysParams = (block) => {
      return {
        id: block.id,
        params: JSON.stringify({
          parent: block.parent,
          coords: block.footprint_lnglat_coords,
          storeys: block.num_storey,
          ish: block.interstorey_height
        })
      }
    }

    if (this._prjRef.windspeed != compareTo.wind_speed) return false
    if (this.lateral_system_name != compareTo.lateral_system_name) return false
    if (this.UG.num_storeys != compareTo.UG.num_storeys) return false
    if (this.UG.ISH != compareTo.UG.ISH) return false

    let cmpBlocks = compareTo.blocks.map((b) => LSysParams(b))
    let thisBlocks = this.blocks.map((b) => LSysParams(b))

    for (let i of thisBlocks) {
      if (!cmpBlocks.find((j) => i.id == j.id && i.params == j.params)) {
        return false
      }
    }

    return true
  }

  serializeToGeomLateralSys(project) {
    return {
      wind_speed: project.windspeed,
      lateral_system_name: this.lateral_system_name,
      UG: {
        num_storeys: this.UG_storeys,
        ISH: this.UG_ish || 3.5
      },
      blocks: this.blocks.map((b) => ({
        id: b.id,
        parent: b.parent,
        footprint_lnglat_coords: b.footprint_lnglat_coords,
        num_storey: b.num_storey,
        interstorey_height: b.interstorey_height
      }))
    }
  }

  serializeToLCA(project) {
    const res = {
      id: this.id,
      project: project.id,
      type: this.type,
      wind_speed: project.windspeed,
      lca_assesment: this.lca_assesment,
      site_lonlat_centroid: this.site_lonlat_centroid,
      lci_dataset: this.lci_dataset,
      lateral_system_name: this.lateral_system_name,
      qto_lateral_sys: this.QTOLateralSys,
      onsite_kgCO2m2: this.onsite_kgCO2m2,
      other_systems_kgCO2m2: this.other_systems_kgCO2m2,
      prev_demo_GIA: this.prev_demo_GIA,
      RSP: this.RSP,
      MEP_params: {
        heating_sys: this.heating_sys,
        cooling_sys: this.cooling_sys,
        lighting_sys: this.lighting_sys,
        ventilation_sys: this.ventilation_sys,

        heating_capacity: this.heating_capacity,
        cooling_capacity: this.cooling_capacity,
        lighting_capacity: this.lighting_capacity,
        ventilation_capacity: this.ventilation_capacity,
        electrical_kgCO2m2: this.electrical_kgCO2m2,
        ventilation_kgCO2m2: this.ventilation_kgCO2m2,
        plumbing_kgCO2m2: this.plumbing_kgCO2m2
      },
      OE: {
        EUI: this.EUI,
        electricity_share_perc: this.electricity_share_perc,
        grid_decarbon_scenario: this.grid_decarbon_scenario,
        grid_coefficient_decarb: this.grid_coefficient_decarb,
        grid_nondecarb_scenario: this.grid_nondecarb_scenario,
        grid_coefficient_nondecarb: this.grid_coefficient_nondecarb
      },
      FDN: {
        type: this.foundation_type,
        soil_type: this.soil_type,
        strengthening_perc: this.foundation_strengthening_perc
      },
      label: this.label,
      blocks: this.blocks.map((b) => b.serializeToLCA()),
      ffe_allowance_perc: this.ffe_allowance_perc,
      external_works_allowance_perc: this.external_works_allowance_perc,
      lateral_strengthening_perc: this.lateral_strengthening_perc
    }

    if (this.UG_storeys > 0)
      res.UG = {
        num_storeys: this.UG_storeys,
        ISH: this.UG_ish
      }

    return res
  }

  transformGeometry(coordinates, rotateAngle) {
    if (!coordinates && !rotateAngle) return

    const rootBlock = this.blocks.find((b) => b.parent == null)
    const poly = turf.polygon([rootBlock.footprint_lnglat_coords])
    const ogCentroid = turf.centroid(poly)

    const newPoly = turf.polygon([coordinates])
    const newCentroid = turf.centroid(newPoly)

    const distance = turf.distance(ogCentroid, newCentroid)
    const bearing = turf.bearing(ogCentroid, newCentroid)
    const direction = turf.bearingToAzimuth(bearing)

    if (rotateAngle) {
      this.blocks.map((block) => {
        block.footprint_lnglat_coords = this.rotateBlock(
          block,
          rotateAngle,
          ogCentroid
        ).geometry.coordinates[0]
      })
    }

    if (distance) {
      this.blocks.map((block) => {
        block.footprint_lnglat_coords = this.translateBlock(
          block,
          distance,
          direction
        ).geometry.coordinates[0]
      })
    }
  }

  rotateBlock(block, rotateAngle, centroid) {
    const poly = turf.polygon([block.footprint_lnglat_coords])
    return turf.transformRotate(poly, Number(rotateAngle), {
      pivot: centroid.geometry.coordinates
    })
  }

  translateBlock(block, distance, direction) {
    const poly = turf.polygon([block.footprint_lnglat_coords])
    return turf.transformTranslate(poly, Number(distance), Number(direction))
  }

  setDefaultsFromUserPermissions(fields) {
    fields.map((field) => {
      if (!field.options.length) return
      if (Object.hasOwn(this, field.name)) this[field.name] = field.options[0]
      if (field.name.startsWith('block')) {
        const fieldName = field.name.split('.')[1]
        this.blocks.map((b) => {
          if (['facade_system', 'glazing_system'].includes(fieldName)) {
            return (b[fieldName] = [{ system: field.options[0], coverage_perc: 50 }])
          }
          if (Object.hasOwn(b, fieldName)) b[fieldName] = field.options[0]
        })
      }
    })
  }

  get carbonByStage() {
    const initLCA = new LCAStages()
    const nonDecarbMaterials = this.materials.filter(({ scenario }) => scenario == 'NONDECARB')
    return nonDecarbMaterials.reduce((stages, mat) => {
      stages.A1A3 += mat.co2KG_A1A3
      stages.A1A3_biogenic += mat.co2KG_A1A3_biogenic
      stages.A4 += mat.co2KG_A4
      stages.A5_1 += mat.co2KG_A5_1
      stages.A5_2 += mat.co2KG_A5_2
      stages.A5_3 += mat.co2KG_A5_3
      stages.B1_1 += mat.co2KG_B1_1
      stages.B1_2 += mat.co2KG_B1_2
      stages.B2 += mat.co2KG_B2
      stages.B3 += mat.co2KG_B3
      stages.B4 += mat.co2KG_B4
      stages.C1 += mat.co2KG_C1
      stages.C2 += mat.co2KG_C2
      stages.C3 += mat.co2KG_C3
      stages.C4 += mat.co2KG_C4
      stages.D1 += mat.co2KG_D1
      return stages
    }, initLCA)
  }

  getCarbonByElement(stages = LCAStagesGroups.C4) {
    const initLCA = new LCABuildingElements()
    const nonDecarbMaterials = this.materials.filter(({ scenario }) => scenario == 'NONDECARB')
    return nonDecarbMaterials.reduce((elements, mat) => {
      let cStages = [
        mat.co2KG_A1A3,
        mat.co2KG_A1A3_biogenic,
        mat.co2KG_A4,
        mat.co2KG_A5_1,
        mat.co2KG_A5_2,
        mat.co2KG_A5_3,
        mat.co2KG_B1_1,
        mat.co2KG_B1_2,
        mat.co2KG_B2,
        mat.co2KG_B3,
        mat.co2KG_B4,
        mat.co2KG_C1,
        mat.co2KG_C2,
        mat.co2KG_C3,
        mat.co2KG_C4,
        mat.co2KG_D1
      ]
      for (let i = 0; i <= Object.values(LCAStagesGroups).indexOf(stages); i++) {
        elements[mat.building_element] += cStages[i]
      }
      return elements
    }, initLCA)
  }

  resetFDNStrengthening() {
    this.foundation_strengthening_perc = 100
  }

  resetLateralStrengthening() {
    this.lateral_strengthening_perc = 100
  }
}
