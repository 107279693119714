import { defineStore } from 'pinia'
import { ref } from 'vue'
import { pApiClient } from '@/library/api'
import mixpanel from 'mixpanel-browser'

export const useLicenceStore = defineStore('licence', () => {
  const licence = ref(null)
  const loading = ref(false)

  async function fetchLicence() {
    if (licence.value || loading.value) return

    loading.value = true
    const { data } = await pApiClient.get('/licence/', { suffix: 's/' })
    licence.value = new Licence(data)

    mixpanel.people.set({ licence: licence.value.name, freemium: licence.value.is_freemium })
    loading.value = false
  }

  async function checkProjectLimit(currentProjectCount) {
    if (!licence.value) await fetchLicence()
    return currentProjectCount >= licence.value.project_count_limit
  }

  async function checkDesignLimit(currentDesignCount) {
    if (!licence.value) await fetchLicence()
    return currentDesignCount >= licence.value.design_count_limit
  }

  return {
    licence,
    fetchLicence,
    checkProjectLimit,
    checkDesignLimit
  }
})

class Licence {
  constructor(licence) {
    this.name = licence.name
    this.description = licence.description
    this.is_subscription = licence.is_subscription
    this.user_count_limit = licence.user_count_limit
    this.is_upgradable = licence.is_upgradable
    this.project_count_limit = licence.project_count_limit
    this.design_count_limit = licence.design_count_limit
    this.sqm_usage_limit = licence.sqm_usage_limit
    this.design_sqm_limit = licence.design_sqm_limit
    this.project_sqm_limit = licence.project_sqm_limit
    this.is_freemium = licence.is_freemium
  }
}
