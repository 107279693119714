<template>
  <v-sheet class="px-4 py-2" color="surface-v-5">
    <v-sheet class="mx-auto" max-width="1400" color="transparent">
      <div class="d-flex flex-column flex-md-row justify-space-between mb-8">
        <div>
          <h1 class="text-button font-weight-bold">Detailed Results</h1>
          <h2 class="text-overline">
            <span class="font-weight-bold me-2">Building Design: </span>
            <span> {{ selectedBuilding?.label }} </span>
          </h2>
        </div>

        <building-selector />
      </div>

      <v-row>
        <v-col cols="12" lg="6">
          <v-card>
            <v-card-title>
              <h3 class="text-subtitle-1 font-weight-bold">Building Details</h3>
            </v-card-title>

            <v-card-text>
              <v-table v-if="buildingDetails" density="compact">
                <tbody>
                  <tr>
                    <th class="text-left font-weight-bold">Foundation Systems</th>
                    <td class="text-right">{{ buildingDetails.foundation }}</td>
                  </tr>
                  <tr>
                    <th class="text-left font-weight-bold">Number of UG Storeys</th>
                    <td class="text-right">{{ buildingDetails.ugStoreys }}</td>
                  </tr>
                  <tr>
                    <th class="text-left font-weight-bold">Structural Systems</th>
                    <td class="text-right">{{ buildingDetails.structuralSystems.join(', ') }}</td>
                  </tr>
                  <tr>
                    <th class="text-left font-weight-bold">Avg. Grid Size</th>
                    <td class="text-right">{{ buildingDetails.avgGridSize }}</td>
                  </tr>
                  <tr>
                    <th class="text-left font-weight-bold">Wall Types</th>
                    <td class="text-right" style="max-width: 300px">
                      {{ buildingDetails.wallTypes.join(', ') }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left font-weight-bold">Glazing Types</th>
                    <td class="text-right" style="max-width: 300px">
                      {{ buildingDetails.glazingType.join(', ') }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left font-weight-bold">Average Glazing Ratio</th>
                    <td class="text-right">{{ buildingDetails.averageGlazingRatio }}%</td>
                  </tr>
                  <tr>
                    <th class="text-left font-weight-bold">Heating System</th>
                    <td class="text-right">{{ buildingDetails.heatingSystem }}</td>
                  </tr>
                </tbody>
              </v-table>

              <v-skeleton-loader v-else type="table-tbody" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="6">
          <v-card>
            <v-card-title>
              <h3 class="text-subtitle-1 font-weight-bold">Summary of Whole-Life Carbon</h3>
            </v-card-title>

            <v-card-text>
              <v-table v-if="selectedBuilding" density="compact">
                <tbody>
                  <tr>
                    <th class="font-weight-bold">Embodied Carbon</th>
                    <td class="text-right">{{ formatNumber(live.ec_m2) }} kgCO2e/m2</td>
                  </tr>
                  <tr>
                    <th class="font-weight-bold">Operational Carbon</th>
                    <td class="text-right">{{ formatNumber(live.oc_m2) }} kgCO2e/m2</td>
                  </tr>
                  <tr>
                    <th class="font-weight-bold">Whole-life Carbon</th>
                    <td class="text-right">{{ formatNumber(live.wlc_m2) }} kgCO2e/m2</td>
                  </tr>
                  <tr>
                    <th class="font-weight-bold">Total Embodied Carbon</th>
                    <td class="text-right">{{ formatNumber(live.ec) }} kgCO2e</td>
                  </tr>
                  <tr>
                    <th class="font-weight-bold">Total Operational Carbon</th>
                    <td class="text-right">{{ formatNumber(live.oc) }} kgCO2e</td>
                  </tr>
                  <tr>
                    <th class="font-weight-bold">Total Whole-life Carbon</th>
                    <td class="text-right">{{ formatNumber(live.wlc) }} kgCO2e</td>
                  </tr>
                </tbody>
              </v-table>

              <v-skeleton-loader v-else type="table-tbody" />

              <h4 v-show="SCORSRating != null" class="mt-4 text-body-1 font-weight-bold">
                IStructE SCORS Rating
              </h4>
              <div v-show="SCORSRating != null" id="istructe" class="text-center" />
              <div v-show="selectedBuilding && !SCORSRating">
                SCORS Rating for this design is currently unavilable.
                <v-btn variant="text" class="pa-0 mb-1" color="primary" @click="refreshData">
                  Click here
                </v-btn>
                to refresh data.
              </div>

              <template v-if="false">
                <h4 class="mt-4 text-body-1 font-weight-bold">Whole-life carbon network rating</h4>
                <div id="wholelife" class="text-center" />
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6">
          <v-card>
            <v-card-title>
              <h3 class="text-subtitle-1 font-weight-bold">Block Design Details</h3>
            </v-card-title>

            <v-card-text>
              <v-sheet
                v-if="selectedBuilding?.blocks.length > 1"
                class="mx-auto my-5"
                max-width="600"
                color="transparent"
              >
                <v-slide-group prev-icon="chevron_left" next-icon="chevron_right">
                  <v-slide-group-item
                    v-for="block in selectedBuilding.sortedBlocks"
                    :key="block.id"
                  >
                    <v-btn
                      class="ma-2 mt-0 ms-0"
                      size="small"
                      :color="selectedBlock?.id == block.id ? 'blue-grey' : ''"
                      @click="selectedBlockId = block.id"
                    >
                      {{ block.label != '' ? block.label : 'Block ' + block.id }}
                    </v-btn>
                  </v-slide-group-item>
                </v-slide-group>
              </v-sheet>

              <v-table v-if="selectedBlock" density="compact">
                <tbody>
                  <tr>
                    <th class="text-left font-weight-bold">Beam Dimensions</th>
                    <td class="text-right">{{ selectedBlock.beamDimensions }}</td>
                  </tr>

                  <tr>
                    <th class="text-left">Reinforcing Ratio</th>
                    <td class="text-right">{{ selectedBlock.beamReinforcingRatio }}</td>
                  </tr>

                  <tr>
                    <th class="text-left font-weight-bold">Column Dimensions</th>
                    <td class="text-right">{{ selectedBlock.columnDimensions }}</td>
                  </tr>

                  <tr>
                    <th class="text-left">Reinforcing Ratio</th>
                    <td class="text-right">{{ selectedBlock.columnReinforcingRatio }}</td>
                  </tr>

                  <tr>
                    <th class="text-left font-weight-bold">Floor Depth</th>
                    <td class="text-right">{{ selectedBlock.floor_depth }} mm</td>
                  </tr>

                  <tr>
                    <th class="text-left">Reinforcing Ratio</th>
                    <td class="text-right">{{ selectedBlock.floorReinforcingRatio }}</td>
                  </tr>

                  <tr>
                    <th class="text-left font-weight-bold">Instances</th>
                    <td />
                  </tr>

                  <tr>
                    <th class="text-left">Beams</th>
                    <td class="text-right">{{ selectedBlock.beam_instances }}</td>
                  </tr>

                  <tr>
                    <th class="text-left">Secondary Instances</th>
                    <td class="text-right">{{ selectedBlock.beam_sec_instances }}</td>
                  </tr>

                  <tr>
                    <th class="text-left">Columns</th>
                    <td class="text-right">{{ selectedBlock.col_instances }}</td>
                  </tr>
                </tbody>
              </v-table>

              <v-skeleton-loader v-else type="table-tbody" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="6">
          <v-card>
            <v-card-title>
              <h3 class="text-subtitle-1 font-weight-bold">Building Design Details</h3>
            </v-card-title>

            <v-card-text>
              <v-table v-if="selectedBuilding" density="compact">
                <tbody>
                  <tr>
                    <th class="text-left font-weight-bold py-3 text-overline">
                      Underground Storeys
                    </th>
                    <td />
                  </tr>

                  <tr>
                    <th class="text-left font-weight-bold">Beam Dimensions</th>
                    <td class="text-right">{{ selectedBuilding.ugBeamDimensions }}</td>
                  </tr>

                  <tr>
                    <th class="text-left">Reinforcing Ratio</th>
                    <td class="text-right">{{ selectedBuilding.ug_beam_reinf_ratio || 'n/a' }}</td>
                  </tr>

                  <tr>
                    <th class="text-left font-weight-bold">Column Dimensions</th>
                    <td class="text-right">{{ selectedBuilding.ugColumnDimensions || 'n/a' }}</td>
                  </tr>

                  <tr>
                    <th class="text-left">Reinforcing Ratio</th>
                    <td class="text-right">{{ selectedBuilding.ug_col_reinf_ratio || 'n/a' }}</td>
                  </tr>

                  <tr>
                    <th class="text-left font-weight-bold">Floor Depth</th>
                    <td class="text-right">
                      {{ selectedBuilding.ug_floor_depth || 'n/a' }}
                      {{ selectedBuilding.ug_floor_depth ? 'mm' : '' }}
                    </td>
                  </tr>

                  <tr>
                    <th class="text-left">Reinforcing Ratio</th>
                    <td class="text-right">{{ selectedBuilding.ug_slab_reinf_ratio || 'n/a' }}</td>
                  </tr>

                  <tr>
                    <th class="text-left font-weight-bold">Instances</th>
                    <td />
                  </tr>

                  <tr>
                    <th class="text-left">Beams</th>
                    <td class="text-right">{{ selectedBuilding.ug_beam_instances || 'n/a' }}</td>
                  </tr>

                  <tr>
                    <th class="text-left">Secondary Instances</th>
                    <td class="text-right">
                      {{ selectedBuilding.ug_beam_sec_instances || 'n/a' }}
                    </td>
                  </tr>

                  <tr>
                    <th class="text-left">Columns</th>
                    <td class="text-right">{{ selectedBuilding.ug_col_instances || 'n/a' }}</td>
                  </tr>

                  <tr>
                    <th class="text-left font-weight-bold py-3 text-overline">Foundation</th>
                    <td />
                  </tr>

                  <tr>
                    <th class="text-left font-weight-bold">Dimensions</th>
                    <td class="text-right">{{ selectedBuilding.fdnDimensions || 'n/a' }}</td>
                  </tr>

                  <tr>
                    <th class="text-left">Instances</th>
                    <td class="text-right">{{ selectedBuilding.foundation_instances || 'n/a' }}</td>
                  </tr>
                </tbody>
              </v-table>

              <v-skeleton-loader v-else type="table-tbody" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <h3 class="text-subtitle-1 font-weight-bold">Embodied Carbon by Lifecycle Stage</h3>
            </v-card-title>

            <v-card-text>
              <div
                v-if="selectedBuilding"
                class="d-flex flex-column flex-md-row justify-space-between ga-4"
              >
                <div class="d-flex flex-column justify-space-between">
                  <div class="d-flex ga-4">
                    <div class="d-flex flex-column">
                      <div
                        v-for="item in stagesLegend"
                        :key="item.name"
                        class="d-flex align-center ga-1"
                        style="min-width: 60px"
                      >
                        <div
                          :style="{
                            backgroundColor: item.color,
                            width: '0.9rem',
                            height: '0.9rem'
                          }"
                          class="rounded"
                        />
                        <span class="text-caption text-surface-variant">{{ item.name }}</span>
                      </div>
                    </div>
                    <div id="stagesWrapper" />
                  </div>

                  <div class="d-flex flex-column">
                    <span class="font-weight-bold mb-2">Lifecycle Assessment Scope</span>
                    <div class="d-flex w-100 bg-surface-variant" style="gap: 1px; padding: 1px">
                      <div
                        v-for="(_, index) in assessmentBoundaries"
                        :key="index"
                        class="w-25"
                        :class="
                          assessmentBoundaries
                            .slice(index)
                            .includes(selectedBuilding?.lca_assesment)
                            ? 'bg-success'
                            : 'bg-surface'
                        "
                        style="height: 20px"
                      />
                    </div>
                    <div class="d-flex w-100">
                      <div class="w-25 text-center text-caption">A1-A3</div>
                      <div class="w-25 text-center text-caption">A1-A5</div>
                      <div class="w-25 text-center text-caption">A-C</div>
                      <div class="w-25 text-center text-caption">A-D</div>
                    </div>
                  </div>

                  <v-table density="compact">
                    <tbody>
                      <tr>
                        <th class="font-weight-bold">Carbon Dataset</th>
                        <td>{{ currentDataset?.name }}</td>
                      </tr>
                      <tr>
                        <th class="font-weight-bold">LCA Methodology</th>
                        <td>{{ currentDataset?.lca_methodology }}</td>
                      </tr>
                    </tbody>
                  </v-table>
                </div>

                <div class="flex-grow-1 d-flex flex-column flex-md-row ga-4">
                  <v-table density="compact" class="flex-grow-1">
                    <thead>
                      <tr>
                        <th colspan="2" class="font-weight-bold">
                          Embodied carbon normalized by GFA
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in normalizedStagesData"
                        :key="item.name"
                        class="text-caption"
                        style="min-width: 60px"
                      >
                        <td class="font-weight-bold">{{ item.name }}</td>
                        <td class="text-right">{{ formatNumber(item.value, 0) }} kgCO2e/m2</td>
                      </tr>
                    </tbody>
                  </v-table>

                  <v-table density="compact" class="flex-grow-1">
                    <thead>
                      <tr>
                        <th colspan="2" class="font-weight-bold">Total Embodied carbon</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in totalECStages"
                        :key="item.name"
                        class="text-caption"
                        style="min-width: 60px"
                      >
                        <td class="font-weight-bold">{{ item.name }}</td>
                        <td class="text-right">{{ formatNumber(item.value, 0) }} kgCO2e</td>
                      </tr>
                    </tbody>
                  </v-table>
                </div>
              </div>

              <v-skeleton-loader v-else type="table" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <h3 class="text-subtitle-1 font-weight-bold">Embodied Carbon by Element</h3>
            </v-card-title>

            <v-card-text>
              <div class="d-flex ga-6">
                <div
                  v-if="structData.length"
                  class="d-flex justify-center align-center flex-grow-1"
                >
                  <div id="structWrapper" />
                </div>

                <v-table v-if="structData.length" density="compact" height="400" fixed-header>
                  <thead class="bg-surface">
                    <tr>
                      <td />
                      <td />
                      <td class="font-weight-bold">Embodied carbon by element normalized by GFA</td>
                      <td class="font-weight-bold">Total Embodied carbon by building element</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in structData" :key="item.name">
                      <td>
                        <div
                          :style="{
                            backgroundColor: structLegend[index]?.color || '#00000022',
                            width: '0.9rem',
                            height: '0.9rem'
                          }"
                          class="rounded"
                        />
                      </td>
                      <td class="font-weight-bold">{{ item.name }}</td>
                      <td class="text-right">{{ formatNumber(item.value, 0) }} kgCO2e/m²</td>
                      <td class="text-right">{{ formatNumber(item.total, 0) }} kgCO2e</td>
                    </tr>
                  </tbody>
                </v-table>

                <v-skeleton-loader v-else type="table" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useDataViz } from '@/composables/dataViz'
import { useLiveediting } from '@/composables/liveediting'
import DonutChart from '@/library/dataViz/DonutChart'
import MeterChart from '@/library/dataViz/MeterChart'
import { structure, subStructure } from '@/library/appConfigs'
import { useAssemblyStore } from '@/stores/assembly'
import { useDatasetStore } from '@/stores/dataset'
import BuildingSelector from '@/components/controls/BuildingSelector.vue'
import { useProjectStore } from '@/stores/project'
import { useBuildingStore } from '@/stores/building'
import { useMessenger } from '@/composables/messenger'
import { useProgress } from '@/composables/progress'

const ratings = {
  'A++': 1,
  'A+': 12,
  A: 24,
  B: 36,
  C: 48,
  D: 60,
  E: 72,
  F: 84,
  G: 96
}

export default {
  name: 'DetailedResults',

  components: {
    BuildingSelector
  },

  setup() {
    const { assemblies } = storeToRefs(useAssemblyStore())
    const { fetchAssemblies } = useAssemblyStore()
    const { currentProject } = storeToRefs(useProjectStore())
    const { loadProject, setActiveTabs } = useProjectStore()
    const { selectedBuilding, selectFirst, getConfig, selectBuildingById } = useLiveediting()
    const { refreshLiveBox, stagesData, structData, live, formatStagesData } = useDataViz()
    const config = getConfig()
    const { fetchDatasets } = useDatasetStore()
    const { datasets } = storeToRefs(useDatasetStore())
    const { saveBuilding } = useBuildingStore()
    const { addMessage } = useMessenger()
    const { setProgress, resetProgress } = useProgress()

    const stagesLegend = ref([])
    const structLegend = ref([])
    const selectedBlockId = ref(null)

    const assessmentBoundaries = ['A1_A3', 'A1_A5', 'A_C', 'A_D']
    const meterChart = new MeterChart({ width: 500, height: 16 })

    fetchAssemblies()
    fetchDatasets()

    return {
      selectedBlockId,
      selectedBuilding,
      stagesData,
      structData,
      selectFirst,
      stagesLegend,
      structLegend,
      refreshLiveBox,
      currentProject,
      loadProject,
      live,
      config,
      assemblies,
      formatStagesData,
      datasets,
      assessmentBoundaries,
      setActiveTabs,
      meterChart,
      saveBuilding,
      selectBuildingById,
      addMessage,
      setProgress,
      resetProgress
    }
  },

  computed: {
    isRICS() {
      if (!this.selectedBuilding) return false
      const shortcode = this.selectedBuilding.lci_dataset
      const dataset = this.datasets.find((d) => d.shortcode == shortcode)
      return dataset?.lca_methodology === 'RICSv2'
    },

    selectedBlock() {
      if (!this.selectedBuilding) return
      if (!this.selectedBlockId) return this.selectedBuilding.blocks[0]

      return this.selectedBuilding.blocks.find(({ id }) => id === this.selectedBlockId)
    },

    currentDataset() {
      return this.datasets.find(({ shortcode }) => shortcode === this.selectedBuilding?.lci_dataset)
    },

    totalECStages() {
      if (!this.selectedBuilding) return
      const data = this.formatStagesData(this.selectedBuilding, false)
      return this.groupStagesData(data)
    },

    normalizedStagesData() {
      if (!this.selectedBuilding) return
      const data = this.formatStagesData(this.selectedBuilding)
      return this.groupStagesData(data)
    },

    foundationTypes() {
      return subStructure.types.find(
        ({ value }) => value === this.selectedBuilding?.foundation_type
      )?.title
    },

    structuralSystems() {
      const structuralSystems = new Set(
        this.selectedBuilding?.blocks.map(
          ({ structural_system }) =>
            structure.types.find(({ value }) => value === structural_system).title
        )
      )

      return Array.from(structuralSystems)
    },

    avgGridSize() {
      if (!this.selectedBuilding) return
      const gridSizeD1 = this.selectedBuilding.blocks.map((block) => block.d1_grid_size)
      const gridSizeD2 = this.selectedBuilding.blocks.map((block) => block.d2_grid_size)
      const d1Avg = gridSizeD1.reduce((a, b) => a + b, 0) / gridSizeD1.length
      const d2Avg = gridSizeD2.reduce((a, b) => a + b, 0) / gridSizeD2.length
      return `${Math.round(d1Avg)}x${Math.round(d2Avg)}`
    },

    avgGlazingRatio() {
      return (
        this.selectedBuilding?.blocks
          .flatMap((block) => block.glazing_system)
          .reduce((sum, { coverage_perc }) => sum + coverage_perc, 0) /
        this.selectedBuilding?.blocks.length
      ).toFixed()
    },

    heatingSystems() {
      return this.selectedBuilding?.heating_sys
        ? this.config.heating_sys.find(({ id }) => id === this.selectedBuilding?.heating_sys).label
        : ''
    },

    wallTypes() {
      const wallTypes =
        this.selectedBuilding?.blocks.map((block) => this.displayAssemblies(block.facade_system)) ||
        []

      return Array.from(new Set(wallTypes.flat()))
    },

    glazingTypes() {
      const glazingTypes = this.selectedBuilding?.blocks
        .flatMap((block) => block.glazing_system)
        .map(({ system }) => system)
        .map((system) => this.assemblies.find(({ shortcode }) => shortcode === system).name)

      return Array.from(new Set(glazingTypes))
    },

    buildingDetails() {
      if (!this.selectedBuilding) return

      return {
        foundation: this.foundationTypes,
        ugStoreys: this.selectedBuilding.UG_storeys,
        structuralSystems: this.structuralSystems,
        avgGridSize: this.avgGridSize,
        averageGlazingRatio: this.avgGlazingRatio,
        heatingSystem: this.heatingSystems,
        wallTypes: this.wallTypes,
        glazingType: this.glazingTypes
      }
    },

    SCORSRating() {
      return ratings[this.selectedBuilding?.RatingSCORS] || null
    },

    wholelifeRating() {
      return ratings[this.selectedBuilding?.RatingLETI_Lifecycle] || 0
    },

    blockDesignDetails() {
      if (!this.selectedBuilding) return

      return {
        beamDimensions: this.selectedBlock.beam_width * this.selectedBlock.beam_depth
      }
    }
  },

  watch: {
    selectedBuilding() {
      this.refreshLiveBox(this.selectedBuilding)
      this.drawStagesChart()
      this.drawStructChart()
      this.meterChart.draw('#istructe', this.SCORSRating)
      this.selectedBlockId = this.selectedBuilding.blocks[0].id
    }
  },

  async mounted() {
    if (!this.currentProject.id && this.$route.params.projectid) {
      await this.loadProject(this.$route.params.projectid)
      this.setActiveTabs(['project', 'compare', 'liveediting', 'data'])
    }

    if (!this.selectedBuilding) this.selectFirst()

    this.refreshLiveBox(this.selectedBuilding)
    this.$nextTick(() => {
      this.drawStagesChart()
      this.drawStructChart()
      this.meterChart.draw('#istructe', this.SCORSRating)
    })
  },

  methods: {
    formatNumber(num, decimals = 2) {
      return Intl.NumberFormat('en-US', { maximumFractionDigits: decimals }).format(num)
    },

    groupStagesData(data) {
      const getValue = (name) => data.find((stage) => stage.name === name).value

      const ricsStages = this.isRICS
        ? [
            { name: 'B1', value: getValue('B1_2') },
            { name: 'B2', value: getValue('B2') },
            { name: 'B3', value: getValue('B3') }
          ]
        : []

      return [
        { name: 'A1A3', value: getValue('A1A3') },
        { name: 'A4', value: getValue('A4') },
        { name: 'A5', value: getValue('A5_1') + getValue('A5_2') + getValue('A5_3') },
        ...ricsStages,
        { name: 'B4', value: getValue('B4') },
        { name: 'C1-C4', value: getValue('C1') + getValue('C2') + getValue('C3') + getValue('C4') }
      ]
    },

    drawStructChart() {
      const data = this.structData.filter(({ total }) => total > 0)
      const chart = new DonutChart({ data, scale: 0.6, donutWidth: 0.25 })
      chart.drawChart('#structWrapper')

      this.structLegend = chart.legend
    },

    drawStagesChart() {
      if (!this.stagesData.length) return

      const data = this.groupStagesData(this.stagesData)
      const chart = new DonutChart({ data, scale: 0.4 })
      chart.drawChart('#stagesWrapper')
      this.stagesLegend = chart.legend
    },

    displayAssemblies(value) {
      return value.map(this.getAssemblyName)
    },

    getAssemblyName(shortcode) {
      if (Array.isArray(shortcode)) return shortcode.map(this.getAssemblyName)
      if (typeof shortcode === 'object') return this.getAssemblyName(shortcode.system)
      const assembly = this.assemblies.find((item) => item.shortcode === shortcode)
      return assembly ? assembly.name : shortcode
    },

    async refreshData() {
      this.setProgress(20)
      const data = await this.saveBuilding(
        this.selectedBuilding.serializeToLCA(this.currentProject)
      )
      this.selectBuildingById(data.id)
      this.resetProgress()
      this.addMessage({
        text: 'Building data refreshed.',
        color: 'success'
      })
    }
  }
}
</script>
