<template>
  <v-sheet class="pa-8" color="surface-v-5">
    <v-card flat color="transparent">
      <v-card-title class="d-flex gap-3 px-0">
        <v-spacer />
        <v-btn color="primary" prepend-icon="add" @click="addDesign"> Add Design </v-btn>
      </v-card-title>

      <v-table
        v-if="buildings.length"
        id="compare-designs"
        density="compact"
        class="my-10 text-center bg-surface-v-5"
      >
        <tbody>
          <tr id="label">
            <th />
            <th v-for="{ label, id } in buildings" :key="id" class="font-weight-bold text-center">
              {{ label }}
            </th>
          </tr>
          <tr id="buttons">
            <th />
            <th v-for="{ id } in buildings" :key="id" class="py-1 text-center">
              <v-btn
                prepend-icon="download"
                text="PDF"
                stacked
                variant="text"
                size="small"
                density="compact"
                @click="exportPDF(id)"
              />

              <v-btn
                prepend-icon="download"
                text="OBJ"
                stacked
                variant="text"
                size="small"
                density="compact"
                @click="exportOBJ(id)"
              />

              <v-btn
                prepend-icon="download"
                text="XLS"
                stacked
                variant="text"
                size="small"
                density="compact"
                @click="exportXLS(id)"
              />
            </th>
          </tr>
          <tr id="embodied-carbon">
            <th class="font-weight-bold text-center">Embodied Carbon</th>
            <td v-for="{ id, EC_CO2KGm2 } in buildings" :key="id">
              <span class="fw-bold">{{ Math.floor(EC_CO2KGm2.total()) }}</span>
              kgCO<sub>2</sub>/m<sup>2</sup>
            </td>
          </tr>
          <tr id="operational-carbon">
            <th class="font-weight-bold text-center">Operational Carbon</th>
            <td v-for="{ id, OC_B6_CO2KGm2 } in buildings" :key="id">
              <span class="fw-bold">{{ Math.floor(OC_B6_CO2KGm2) }}</span>
              kgCO<sub>2</sub>/m<sup>2</sup>
            </td>
          </tr>
          <tr id="wlc">
            <th class="font-weight-bold text-center">WLC</th>
            <td v-for="{ id, WLCm2 } in buildings" :key="id">
              <span class="fw-bold">{{ Math.floor(WLCm2) }}</span> kgCO<sub>2</sub>/m<sup>2</sup>
            </td>
          </tr>
          <tr id="floor-area">
            <th class="font-weight-bold text-center">Floor Area</th>
            <td v-for="{ id, GFA } in buildings" :key="id">
              {{ floorareaConfig.displayValue(GFA) }} {{ floorareaConfig.um }}
            </td>
          </tr>
          <tr id="wwr">
            <th class="font-weight-bold text-center">WWR</th>
            <td v-for="{ id, WWR } in buildings" :key="id">{{ WWR }}%</td>
          </tr>
          <tr id="structure">
            <th class="font-weight-bold text-center">Structure</th>
            <td
              v-for="{ id, structural_system } in buildings"
              :key="id"
              class="structure-icon"
              v-html="structure(structural_system)"
            />
          </tr>
          <tr id="wall">
            <th class="font-weight-bold text-center">Wall</th>
            <td
              v-for="{ id, facade_system } in buildings"
              :key="id"
              class="max-width-60"
              v-html="displayAssemblies(facade_system)"
            />
          </tr>
          <tr id="roof">
            <th class="font-weight-bold text-center">Roof</th>
            <td
              v-for="{ id, roof_system } in buildings"
              :key="id"
              v-html="displayAssemblies(roof_system)"
            />
          </tr>
          <tr id="live-editing">
            <th class="border-0" />
            <td v-for="building in buildings" :key="building.id" class="border-0">
              <v-btn
                icon="folder_open"
                variant="text"
                size="small"
                class="mx-2"
                :to="`/liveediting/${currentProject.id}?buildingid=${building.id}`"
              />

              <v-dialog width="500">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    icon="delete"
                    size="small"
                    color="error"
                    class="mx-2"
                    variant="text"
                  />
                </template>

                <template #default="{ isActive }">
                  <v-card>
                    <v-card-text color="grey-darken-3">
                      Are you sure you want to delete this building?
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn text="Cancel" color="secondary" @click="isActive.value = false" />
                      <v-btn text="Delete" color="error" @click="onDeleteBuilding(building.id)" />
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </td>
          </tr>
        </tbody>
      </v-table>

      <v-card v-else-if="loading" flat class="text-center pt-16">
        <v-progress-circular indeterminate color="primary" size="large" class="mb-4" />
        <p>Loading designs...</p>
      </v-card>

      <v-card v-else flat class="text-center pt-16">
        <p>You have not created any designs yet.</p>
        <p>Click the button above to add a new design.</p>
      </v-card>

      <div id="parallel-wrapper" class="overflow-auto d-flex flex-column" />
    </v-card>
  </v-sheet>

  <new-design-dialog v-model="newDesignDialog" />

  <v-dialog v-model="buildingLimitDialog" max-width="600px">
    <v-card>
      <v-card-text>
        Oops! It seems you've reached the limit of {{ licence.design_count_limit }} building designs
        with your license.
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text="Close" color="secondary" @click="buildingLimitDialog = false" />
        <v-btn text="View your license" color="primary" to="/account" />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <PDFViewer v-if="pdfURL" :url="pdfURL" @close="pdfURL = null" />
</template>

<script>
import { ConfigsManager } from '@/library/settings'
import { useAssemblyStore } from '@/stores/assembly'
import { storeToRefs } from 'pinia'
import axios, { baseURL } from '@/library/axios'
import PDFViewer from '../PDFViewer.vue'
import { pMapClass } from '@/library/map'
import { useDataViz } from '@/composables/dataViz'
import { useLiveediting } from '@/composables/liveediting'
import { getConfig } from '@/library/appConfigs'
import { useProgress } from '@/composables/progress'
import { ref } from 'vue'
import { useLicenceStore } from '@/stores/licence'
import ParallelCoordsChart from '@/library/dataViz/ParallelCoordsChart'
import { useProjectStore } from '@/stores/project'
import { useBuildingStore } from '@/stores/building'
import NewDesignDialog from '@/components/NewDesignDialog.vue'
import { pApiClient } from '@/library/api'
import { useMessenger } from '@/composables/messenger'

export default {
  name: 'ComparePane',

  components: {
    PDFViewer,
    NewDesignDialog
  },

  setup() {
    const { assemblies } = storeToRefs(useAssemblyStore())
    const { fetchAssemblies } = useAssemblyStore()
    const { currentProject } = storeToRefs(useProjectStore())
    const { setActiveTabs, loadProject } = useProjectStore()
    const { refreshLiveBox, parallelCoordsData } = useDataViz()
    const { selectFirst } = useLiveediting()
    const { setProgress, resetProgress } = useProgress()
    const { fetchLicence } = useLicenceStore()
    const { licence } = storeToRefs(useLicenceStore())
    const { buildings } = storeToRefs(useBuildingStore())
    const { deleteBuilding } = useBuildingStore()
    const { addMessage } = useMessenger()

    fetchAssemblies()
    fetchLicence()

    const newDesignDialog = ref(false)
    const buildingLimitDialog = ref(false)
    const pdfURL = ref(null)
    const loading = ref(false)

    return {
      assemblies,
      currentProject,
      setActiveTabs,
      refreshLiveBox,
      selectFirst,
      resetProgress,
      newDesignDialog,
      licence,
      buildingLimitDialog,
      parallelCoordsData,
      loadProject,
      buildings,
      deleteBuilding,
      pdfURL,
      loading,
      addMessage,
      setProgress
    }
  },

  computed: {
    floorareaConfig() {
      return getConfig(this.currentProject.som).building.floorarea
    },

    isFreemium() {
      return this.licence?.is_freemium
    }
  },

  async created() {
    if (!this.currentProject.id && this.$route.params.projectid) {
      this.loading = true
      await this.loadProject(this.$route.params.projectid)
    }

    if (this.buildings.length) {
      this.setActiveTabs(['project', 'compare', 'liveediting', 'data'])
      this.selectFirst()
      this.$nextTick(this.redrawChart)
    } else {
      this.setActiveTabs(['project', 'compare'])
      this.newDesignDialog = true
    }

    pMapClass.getInstance().destroy()
    this.loading = false
    this.resetProgress()
  },

  methods: {
    redrawChart() {
      this.refreshLiveBox(this.buildings[0])
      const chart = new ParallelCoordsChart(this.parallelCoordsData)
      chart.drawChart('#parallel-wrapper')
    },

    structure(value) {
      const x = value.map((j) => ConfigsManager.getStructureSettings(j).img)
      return x.map((i) => `<img src="/img/${i}" width="35"/>`).join('\n')
    },

    displayAssemblies(value) {
      const assemblyNames = value.map(this.getAssemblyName)
      return this.displayList(assemblyNames)
    },

    displayList(x) {
      const uniqueValues = Array.from(new Set(x.flat()))
      return uniqueValues.map((i) => `<div>${i}</div>`).join('\n')
    },

    getAssemblyName(shortcode) {
      if (Array.isArray(shortcode)) return shortcode.map(this.getAssemblyName)
      if (typeof shortcode == 'object') return this.getAssemblyName(shortcode.system)
      const assembly = this.assemblies.find((item) => item.shortcode == shortcode)
      return assembly ? assembly.name : shortcode
    },

    async onDeleteBuilding(id) {
      this.deleteBuilding(id)
      this.$nextTick(this.redrawChart)
      if (!this.buildings.length) this.setActiveTabs(['project', 'compare'])
    },

    async exportPDF(bID) {
      this.setProgress(10, 'Preparing file...<br />This may take up to 40 seconds.', 90)

      const url = `/bldg/report/${bID}/`

      try {
        const { data } = await pApiClient.get(url, {
          suffix: 's/'
        })
        this.pdfURL = data.pdf
      } catch (err) {
        this.addMessage({ id: url, text: err.message, color: 'error' })
      } finally {
        this.resetProgress()
      }
    },
    exportOBJ(bID) {
      const building = this.buildings.find(({ id }) => id == bID)
      const url = baseURL + 's/bldg/obj/' + building.id
      const label = this.currentProject.name + '_' + building.label + '.obj'
      this.downloadFile(url, label, 'obj')
    },

    exportXLS(bID) {
      const building = this.buildings.find(({ id }) => id == bID)
      const url = baseURL + 's/bldg/xls/' + building.id
      const label = this.currentProject.name + '_' + building.label + '.xlsx'
      this.downloadFile(url, label, 'vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    },

    downloadFile(url, label, filetype) {
      axios
        .get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/' + filetype })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },

    addDesign() {
      if (!this.licence) return

      if (this.buildings.length >= this.licence.design_count_limit) {
        this.buildingLimitDialog = true
        return
      }
      this.newDesignDialog = true
    }
  }
}
</script>

<style>
#compare-designs table {
  max-width: fit-content;
}

.max-width-60 {
  max-width: 60px;
}
</style>
