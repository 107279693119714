<template>
  <div id="lca-progress" />
  <v-layout class="h-screen">
    <Toolbar />

    <router-view name="lsb" />
    <v-main class="bg-surface-v-5">
      <v-banner v-if="showUpgradeBanner" bg-color="amber-lighten-1" stacked mobile-breakpoint="md">
        <v-banner-text class="text-body-1">
          Welcome to Preoptima CONCEPT LITE! We are excited to help you optioneer your building
          designs to deliver the next generation of low-carbon buildings. You currently have a free
          license to the software. Upgrade for access to import your own building geometry, the RICS
          methodology, unlimited building designs, custom carbon datasets, custom assemblies,
          project sharing, and more!
        </v-banner-text>

        <v-banner-actions>
          <v-btn to="/account" variant="elevated" color="success" class="d-none d-sm-flex">
            Upgrade licence
          </v-btn>
        </v-banner-actions>
      </v-banner>
      <router-view />
    </v-main>
  </v-layout>

  <OnboardingWrapper />
  <ProgressBar />
</template>

<script setup>
import { computed } from 'vue'
import { ConfigsManager, GetConfigs } from '@/library/settings'
import { useAssemblyStore } from '@/stores/assembly'
import { pMapClass } from '@/library/map'
import { useMessenger, MESSAGES } from '@/composables/messenger'
import Toolbar from '@/components/Toolbar.vue'
import { Project } from '@/library/models/Project'
import ProgressBar from '@/components/ProgressBar.vue'
import { useTheme } from '@/composables/theme'
import { useLicenceStore } from '@/stores/licence'
import { storeToRefs } from 'pinia'
import { useProjectStore } from '@/stores/project'
import { useRoute } from 'vue-router'
import OnboardingWrapper from '@/components/OnboardingWrapper.vue'

const { addMessage } = useMessenger()
const { currentProject, currentSoM } = storeToRefs(useProjectStore())
const { fetchAssemblies } = useAssemblyStore()
const { fetchLicence } = useLicenceStore()
const { licence } = storeToRefs(useLicenceStore())
useTheme()

const route = useRoute()

try {
  GetConfigs.run()
  currentProject.value = new Project()
  currentSoM.value = currentProject.value.som
  new pMapClass(ConfigsManager.MAP_TOKEN)
} catch (e) {
  addMessage({
    color: 'error',
    text: MESSAGES.ERROR_GENERAL
  })
}

fetchAssemblies()
fetchLicence()

const showUpgradeBanner = computed(() => {
  if (!licence.value?.is_freemium) return false
  return route.path === '/' || route.path.startsWith('/compare')
})
</script>
