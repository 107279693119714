import { ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { pApiClient } from '@/library/api'
import { MESSAGES, useMessenger } from '@/composables/messenger'
import { Project } from '@/library/models/Project'
import { useLiveediting } from '@/composables/liveediting'
import { useBuildingStore } from './building'

const { addMessage } = useMessenger()

export const useProjectStore = defineStore('project', () => {
  const projects = ref([])
  const currentProject = ref(new Project())
  const undoProject = ref(null)
  const isNewProject = ref(false)
  const loading = ref(false)
  const currentSoM = ref(null)
  const buildingList = ref([])
  const tabs = ref({
    project: true,
    compare: false,
    liveediting: false,
    data: false
  })

  const { project: buildingStoreProject } = storeToRefs(useBuildingStore())
  const { setProject } = useBuildingStore()

  async function loadProjects(reload = false) {
    if (projects.value.length > 0 && !reload) return

    const url = 'project'
    try {
      loading.value = true
      const { data } = await pApiClient.get(url)
      projects.value = data.map((project) => new Project(project))
    } catch (e) {
      addMessage({ id: url, text: MESSAGES.PROJECT_LIST_ERROR, color: 'error' })
    } finally {
      loading.value = false
    }
  }

  async function loadProject(id, reload = false) {
    if (projects.value.length > 0 && !reload) {
      const project = projects.value.find((project) => project.id === Number.parseInt(id))
      currentProject.value = new Project(project)
    } else {
      const url = `project/${id}/`
      try {
        const { data } = await pApiClient.get(url)
        currentProject.value = new Project(data)
      } catch (e) {
        addMessage({ id: url, text: MESSAGES.PROJECT_LOAD_ERROR, color: 'error' })
      }
    }

    if (!currentProject.value?.id) {
      return addMessage({ text: MESSAGES.PROJECT_LOAD_ERROR, color: 'error' })
    }

    undoProject.value = structuredClone(currentProject.value)
    isNewProject.value = false

    useLiveediting().setCurrentProject(currentProject.value)
    await setProject(currentProject.value)
  }

  async function saveProject() {
    const exisitingProject = currentProject.value.id && currentProject.value.id > 0
    const method = exisitingProject ? 'put' : 'post'
    const url = exisitingProject ? `project/${currentProject.value.id}` : 'project'
    if (!currentProject.value.budget) currentProject.value.budget = 0
    if (!currentProject.value.carbon_price) currentProject.value.carbon_price = 0

    const { data } = await pApiClient[method](url, currentProject.value)
    await loadProjects(true)
    await loadProject(data.id)
  }

  async function deleteProject(id) {
    const url = `project/${id}/`
    try {
      await pApiClient.delete(url)
      await loadProjects(true)
      addMessage({ id: url, text: MESSAGES.PROJECT_DELETE_SUCCESS, color: 'success' })
    } catch (e) {
      addMessage({ id: url, text: MESSAGES.PROJECT_DELETE_ERROR, color: 'error' })
    }
  }

  function newProject() {
    currentProject.value = new Project()
    undoProject.value = null
    isNewProject.value = true
    buildingStoreProject.value = null
  }

  function resetProject() {
    if (undoProject.value == null) return
    currentProject.value = new Project(undoProject.value)
  }

  function setActiveTabs(activeTabs) {
    for (let tab in tabs.value) {
      tabs.value[tab] = activeTabs.includes(tab)
    }
  }

  return {
    loadProjects,
    loadProject,
    saveProject,
    deleteProject,
    newProject,
    resetProject,
    setActiveTabs,
    projects,
    currentProject,
    isNewProject,
    currentSoM,
    loading,
    tabs,
    buildingList
  }
})
