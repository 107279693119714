<template>
  <v-dialog v-model="show" max-width="450px">
    <v-card>
      <v-card-title class="px-6 py-3 d-flex">
        <span> Update carbon data </span>
        <v-spacer />
        <span class="text-overline">
          Material {{ currentMaterialIndex + 1 }} of {{ materialsToShow.length }}
        </span>
      </v-card-title>

      <v-card-text v-if="currentMaterial">
        <v-form ref="form">
          <template v-for="(val, key) of filteredFields" :key="key">
            <v-select
              v-if="key === 'tr_method'"
              v-model="currentMaterial.tr_method"
              :items="[
                { title: 'Road', value: 'ROAD' },
                { title: 'Sea', value: 'SEA' },
                { title: 'Rail', value: 'RAIL' }
              ]"
              label="Transport Method"
            />

            <v-text-field
              v-else
              :id="key"
              v-model="currentMaterial[key]"
              :label="getLabel(val)"
              :suffix="getUOM(val)?.replace(')', '')"
              :readonly="key === 'name'"
              :type="key == 'name' || key == 'tr_method' ? 'text' : 'number'"
              hide-spin-buttons
              :maxlength="50"
              :required="currentDataset.lca_methodology == 'RICSv2' || key == 'A1A3'"
              :rules="key == 'A1A3' ? a1a3Rules : []"
            />
          </template>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex w-100 justify-space-between">
          <div class="d-flex ga-2">
            <v-btn color="primary" :disabled="isFirstMaterial" @click="prevMaterial">
              Previous
            </v-btn>
            <v-btn color="primary" :disabled="isLastMaterial" @click="nextMaterial"> Next </v-btn>
          </div>

          <div class="d-flex ga-2">
            <v-btn color="secondary" @click="show = false"> Close </v-btn>
            <v-btn color="success" @click="save">Save</v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useDatasetStore } from '@/stores/dataset'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

export default {
  props: {
    materials: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean
    },
    dataset: {
      type: String,
      default: null
    }
  },

  emits: ['update:modelValue'],

  setup() {
    const { carbonDataFields, updateCarbonData, getDatasetByShortcode } = useDatasetStore()
    const { currentMaterial, currentDataset, errorMaterials } = storeToRefs(useDatasetStore())

    const a1a3Rules = [
      (value) => {
        return value !== undefined || 'A value is required'
      }
    ]
    const form = ref()

    return {
      currentMaterial,
      carbonDataFields,
      currentDataset,
      updateCarbonData,
      getDatasetByShortcode,
      errorMaterials,
      a1a3Rules,
      form
    }
  },

  computed: {
    materialsToShow() {
      return this.materials.length ? this.materials : this.errorMaterials
    },

    currentMaterialIndex() {
      return this.materialsToShow.findIndex(({ id }) => id === this.currentMaterial.id)
    },

    isFirstMaterial() {
      if (!this.currentMaterial) return false
      return this.currentMaterialIndex === 0
    },

    isLastMaterial() {
      if (!this.currentMaterial) return false
      return this.currentMaterialIndex === this.materialsToShow.length - 1
    },

    show: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('update:modelValue', value)
      }
    },

    filteredFields() {
      this.getDatasetByShortcode(this.dataset)
      if (this.currentDataset.lca_methodology == 'RICSv2') return this.carbonDataFields

      const filtered = Object.keys(this.carbonDataFields).filter((key) =>
        ['name', 'A1A3'].includes(key)
      )

      return filtered.reduce((obj, key) => {
        obj[key] = this.carbonDataFields[key]
        return obj
      }, {})
    }
  },

  watch: {
    currentMaterial() {
      this.$nextTick(() => {
        document.getElementById('A1A3')?.focus()
      })
    }
  },

  methods: {
    getLabel(field) {
      return field.split(' (')[0]
    },

    getUOM(field) {
      return field.split(' (')[1]
    },

    async save(close = true) {
      const { valid } = await this.form.validate()
      if (!valid) return

      await this.updateCarbonData()
      this.show = !close
    },

    async nextMaterial() {
      await this.save(false)
      if (this.currentMaterialIndex == this.materialsToShow.length - 1) return
      this.currentMaterial = this.materialsToShow[this.currentMaterialIndex + 1]
    },

    async prevMaterial() {
      await this.save(false)
      if (this.currentMaterialIndex == 0) return
      this.currentMaterial = this.materialsToShow[this.currentMaterialIndex - 1]
    }
  }
}
</script>
