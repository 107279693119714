import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
export { MESSAGES } from '@/library/messages'

const messages = ref([])

export function useMessenger() {
  function removeClosedMessages() {
    messages.value = messages.value.filter((m) => m.show)
  }

  function addMessage(message) {
    removeClosedMessages()
    if (messages.value.length >= 5) messages.value.pop()

    if (message.id) {
      const duplicate = messages.value.find((m) => m.id === message.id)
      if (duplicate) duplicate.show = false
    }

    messages.value.unshift(new Message(message))
  }

  function removeMessage(index) {
    messages.value[index].show = false
    removeClosedMessages()
  }

  return {
    addMessage,
    removeMessage,
    messages
  }
}

class Message {
  constructor(message) {
    this.id = message.id || uuidv4()
    this.show = true
    this.title = message.title || ''
    this.text = message.text || ''
    this.action = message.action || { to: '', text: '', callback: null }
    this.timeout = message.timeout || 6000
    this.color = message.color || ''
    this.details = message.details || ''
  }
}
